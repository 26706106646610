import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';

import { generateCallId, rossumMessage } from '../../lib/rossumMessage';
import {
  MessageMethodCalls,
  rossumRpcMessageType,
} from '../../lib/rossumMessagesTypes';
import Codeblock from './components/Codeblock';
import TabPanel from './components/TabPanel';

type Props = {
  methodCall: MessageMethodCalls;
  PayloadComponent?: (props: {
    setLatestMessage: (message: unknown) => void;
  }) => JSX.Element;
  exampleParams?: Record<string, unknown>;
};

const MessageCard = ({
  methodCall,
  PayloadComponent,
  exampleParams,
}: Props) => {
  const [latestMessage, setLatestMessage] = useState<unknown>({});
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState(0);

  const [methodName, params] = methodCall;

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const callIdRef = useRef(generateCallId());

  return (
    <Card>
      <CardContent sx={expanded ? undefined : { ':last-child': { pb: 2 } }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6" component="div">
            {methodName}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              variant="contained"
              onClick={() =>
                rossumMessage(...methodCall)
                  .then(setLatestMessage)
                  .catch(setLatestMessage)
              }
              size="small"
            >
              Send message
            </Button>
            <IconButton onClick={() => setExpanded(!expanded)}>
              <ExpandMore />
            </IconButton>
          </Stack>
        </Stack>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} sx={{ marginTop: 0 }}>
              <Tab label="Latest message" />
              <Tab label="Example" />
              {PayloadComponent ? <Tab label="Payload" /> : null}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Codeblock>{JSON.stringify(latestMessage, null, 2)}</Codeblock>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Codeblock>
              {JSON.stringify(
                {
                  type: rossumRpcMessageType,
                  methodCall: {
                    methodName,
                    params: params || exampleParams,
                  },
                  callId: callIdRef.current,
                },
                null,
                2
              )}
            </Codeblock>
          </TabPanel>
          {PayloadComponent ? (
            <TabPanel value={value} index={2}>
              <PayloadComponent setLatestMessage={setLatestMessage} />
            </TabPanel>
          ) : null}
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default MessageCard;
