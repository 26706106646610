import { KeyboardArrowDown } from '@mui/icons-material';
import { Button, Chip, Collapse, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Children, ReactNode, useEffect, useState } from 'react';

type Props = {
  onAction?: () => void;
  actionButtonText?: string;
  title: string;
  subheader: string;
  isExpandable?: boolean;
  children: ReactNode;
  cardCount?: number;
  containsErrors?: boolean;
};

export const SectionContainer = ({
  onAction,
  title,
  subheader,
  children,
  isExpandable,
  cardCount,
  containsErrors,
  actionButtonText,
}: Props) => {
  const hasNoChildren = Children.toArray(children).length === 0;

  const [hasExpanded, setHasExpanded] = useState(hasNoChildren);

  useEffect(() => {
    if (containsErrors) setHasExpanded(true);
  }, [containsErrors]);

  const isSectionClickable = isExpandable && !containsErrors;

  return (
    <Stack
      sx={{
        maxHeight: '50%',
        overflow: 'hidden',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
        sx={{
          cursor: isSectionClickable ? 'pointer' : 'auto',
        }}
        {...(isSectionClickable && {
          onClick: () => setHasExpanded(!hasExpanded),
        })}
      >
        <Stack spacing={1}>
          <Stack direction="row" spacing={2}>
            <Typography variant="h5">{title}</Typography>
            {cardCount !== undefined ? (
              <Chip label={cardCount} size="small" />
            ) : null}
          </Stack>
          <Typography fontSize="small" sx={{ color: 'text.secondary' }}>
            {subheader}
          </Typography>
          {containsErrors ? (
            <Typography fontSize="small" color="error">
              Make sure you don't have any invalid fields
            </Typography>
          ) : null}
        </Stack>

        {isExpandable && (
          <IconButton sx={{ height: 'fit-content' }} disabled={containsErrors}>
            <KeyboardArrowDown
              sx={{
                transform: hasExpanded ? 'rotate(180deg)' : 'none',
                transition: theme => theme.transitions.create('transform'),
              }}
            />
          </IconButton>
        )}
      </Stack>

      <Collapse
        in={isExpandable ? hasExpanded : true}
        timeout={500}
        sx={{ visibility: 'unset' }}
      >
        <Stack>
          {onAction ? (
            <Button variant="text" sx={{ ml: 'auto' }} onClick={onAction}>
              {actionButtonText}
            </Button>
          ) : null}
          {children}
        </Stack>
      </Collapse>
    </Stack>
  );
};
