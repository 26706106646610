import { Formats } from './types';

export const annotationFormats: Exclude<Formats, 'pdf'>[] = [
  'xml',
  'json',
  'xlsx',
  'csv',
];
export const documentFormats: Extract<Formats, 'pdf'>[] = ['pdf'];

export const secretPlaceholder = '*****';

export const secretFields = ['password'] as const;

// REGEX
export const stringInCurlyBraces = /\{(.*?)\}/g;
export const stringWithoutWhiteSpace = /^[^\s]*$/;
