import { Queue } from './queue';
import { FlatSchemaPropertyValue } from './schema';

export type FieldProperty = {
  values: (FlatSchemaPropertyValue | Queue)[];
};

export const propertiesKey = [
  'label',
  'type',
  'category',
  'queues',
  'ui_configuration',
  'ui_configuration_type',
  'ui_configuration_edit',
  'rir_field_names',
] as const;
export type FieldPropertiesKey = (typeof propertiesKey)[number];

export type FieldProperties = Partial<
  Record<FieldPropertiesKey, FieldProperty>
>;

export type AnalyzedFields = Record<string, FieldProperties>;
