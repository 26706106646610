import { FieldManagerContainer as FieldManager } from './Apps/FieldManager/FieldManager';
import { SFTPExportExtension } from './Extensions/SFTP/exportExtension';
import { TestMessages } from './Extensions/TestMessages/TestMessages';
import { routes } from './routes';
import { useSmartlook } from './smartlook/useSmartlook';
import { useTrackExtensionEntered } from './tracking';
import { useSentrySetUser } from './useSentrySetUser';

function App() {
  useTrackExtensionEntered();
  useSmartlook();
  useSentrySetUser();

  switch (window.location.pathname) {
    case routes.testMessages:
      return <TestMessages />;

    case routes.sftpExport:
      return <SFTPExportExtension />;

    case routes.fieldManager:
      return <FieldManager />;

    default:
      return null;
  }
}

export default App;
