import { GridBooleanCell, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { text } from '../../../constants';
import { GridRowModel } from '../../rows';

export const renderBooleanCell = (params: GridRenderCellParams<GridRowModel>) =>
  params.isEditable ? <GridBooleanCell {...params} /> : text.cellNotEditable;

// our custom valueGetter is sending value that isn't in the options
// params.formattedValue resolves to empty string and params.value to undefined
// this allows the content for empty cell from dataGridStyles.ts to be used
export const renderSingleSelectCell = (
  params: GridRenderCellParams<GridRowModel>
) => (
  <div className="singleSelectCellContent">
    {(params.formattedValue || params.value) ??
      (params.isEditable ? undefined : text.cellNotEditable)}
  </div>
);
