import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { rossumMessage } from '../../../lib/rossumMessage';
import { QueuesResponse } from '../types/queue';

export const useGetQueues = () => {
  const queuesPaginatedQuery = useInfiniteQuery({
    queryKey: ['queues-paginated'],
    queryFn: ({ pageParam = 1 }) =>
      rossumMessage<QueuesResponse>('ApiRequest.get', {
        endpoint: '/queues',
        query: {
          page_size: 100,
          page: pageParam,
          deleting: false,
          fields: ['workspace', 'schema', 'name', 'id', 'url'],
        },
      }).then(rpcResponse => rpcResponse.methodResponse.value),
    getNextPageParam: lastPage =>
      lastPage.pagination.next
        ? Number(lastPage.pagination.next.match(/page=(\d*)/)?.[1])
        : null,
  });

  const { isFetching, hasNextPage, fetchNextPage } = queuesPaginatedQuery;

  useEffect(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetching, hasNextPage, fetchNextPage]);

  return queuesPaginatedQuery;
};
