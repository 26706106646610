import { Stack } from '@mui/system';
import { useFormState } from 'react-hook-form';

import { SFTPExportFormParams } from '../types';
import { SectionContainer } from '../ui/SectionContainer';
import { TextInput } from '../ui/TextInput';

type Props = Required<Pick<SFTPExportFormParams, 'control'>>;

export const SFTPSettings = ({ control }: Props) => {
  const { errors } = useFormState({ control });

  return (
    <SectionContainer
      title="SFTP Settings"
      subheader="Configure your SFTP server settings"
    >
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <TextInput
            label="Host"
            control={control}
            name="host"
            sx={{ flex: 0.7 }}
            errors={errors}
            helperText={`e.g. "rossum-export.server.customer.com"`}
          />
          <TextInput
            label="Port"
            control={control}
            name="port"
            sx={{ flex: 0.3 }}
            errors={errors}
            helperText={`e.g. "22"`}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextInput
            label="Username"
            control={control}
            name="username"
            errors={errors}
            sx={{ flex: 0.5 }}
            helperText={`e.g. "sftp-user"`}
          />
          <TextInput
            label="Password"
            control={control}
            name="password"
            type="password"
            errors={errors}
            sx={{ flex: 0.5 }}
          />
        </Stack>
      </Stack>
    </SectionContainer>
  );
};
