import { inputBaseClasses, Stack, SxProps, TextField } from '@mui/material';
import { FocusEvent, HTMLInputTypeAttribute, ReactElement } from 'react';
import {
  Controller,
  FieldErrors,
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { SFTPExtension } from '../types';
import { ErrorMessage } from './ErrorMessage';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Pick<
  UseControllerProps<TFieldValues, TName>,
  'name' | 'control' | 'defaultValue'
> & {
  label: string;
  sx?: SxProps;
  type?: HTMLInputTypeAttribute;
  errors?: FieldErrors<SFTPExtension>;
  onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  endAdornment?: ReactElement;
  multiline?: boolean;
  helperText?: string;
};

export const TextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  defaultValue,
  label,
  sx,
  type = 'text',
  errors,
  onFocus,
  onBlur,
  endAdornment,
  multiline,
  helperText,
}: Props<TFieldValues, TName>) => {
  const {
    fieldState: { error },
  } = useController({ control, name });

  const handleBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    onFieldBlur: () => void
  ) => {
    if (onBlur) onBlur(e);
    onFieldBlur();
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onBlur: onFieldBlur, ref, ...rest } }) => (
        <Stack width={1} sx={sx}>
          <TextField
            label={label}
            variant="outlined"
            type={type}
            fullWidth
            multiline={multiline}
            value={value ?? defaultValue ?? ''}
            sx={{
              ...(error && {
                [`& .${inputBaseClasses.root}`]: {
                  '& fieldset': {
                    borderColor: 'error.main',
                  },
                },
              }),
            }}
            helperText={helperText}
            InputProps={{
              style: {
                height: '100%',
              },
              endAdornment,
            }}
            onFocus={onFocus}
            onBlur={e => handleBlur(e, onFieldBlur)}
            inputRef={ref}
            {...rest}
          />
          {errors && <ErrorMessage errors={errors} name={name} />}
        </Stack>
      )}
    />
  );
};
