import { DeleteOutline } from '@mui/icons-material';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-pro';
import { compact, fromPairs, get, isEqual, negate } from 'lodash';

import { FlatSchemaPropertyValue } from '../../../types/schema';
import { text } from '../../constants';
import {
  categoryOptions,
  typeOptions,
  uiConfigurationEditOptions,
  uiConfigurationTypeOptions,
} from '../../data/options';
import { injectStylesToOperators } from '../../ui/dataGridStyles';
import { getFieldType, GridRowModel } from '../rows';
import { RemoveDialogParamsSetter } from '../useRemoveFromQueues';
import {
  canHaveRirFieldNames,
  isDatapoint,
  isDatapointInTable,
  isNotButtonDatapoint,
  isNotButtonHeaderDatapoint,
  isNotTuple,
  isNotTupleNorSection,
  isNumberOrDate,
} from './editabilityConditions';
import { queuesOperators } from './filterOperators';
import { renderFormatEditCell } from './format';
import {
  appendPercentSignToNumber,
  percentageToValue,
  validatePercentageRange,
  valueToPercentage,
} from './percentages';
import { queuesComparator, rirFieldNamesComparator } from './sortComparators';
import { ExtendedCellParams, GridColumns } from './types';
import { renderChipsCell } from './ui/ChipsCell';
import { renderMultipleEditAutocomplete } from './ui/EditCellAutocomplete';
import { EditCellWithValidation } from './ui/EditCellWithValidation';
import { renderQueuesLinks } from './ui/QueuesLinks';
import { renderBooleanCell, renderSingleSelectCell } from './ui/renderCell';
import { validateNonEmptyString, validatePositiveInteger } from './validations';

export const defaultValues = {
  rir_field_names: null,
  format: null,
  default_value: null,
  hidden: false,
  can_export: true,
  can_collapse: false,
  disable_prediction: false,
  width: null,
  stretch: false,
  score_threshold: null,
  constraints: { required: true },
};

const getFieldValue: GridColumns[number]['valueGetter'] = (
  params: ExtendedCellParams
) => {
  const shouldFallbackToDefault =
    params.colDef.editable && params.colDef.editabilityCondition
      ? params.colDef.editabilityCondition(params.row.meta)
      : params.colDef.editable;

  const value: FlatSchemaPropertyValue =
    get(params.row, params.field) ??
    (shouldFallbackToDefault
      ? get(defaultValues, params.field, '')
      : text.cellNotEditable);

  return value;
};

type GetColumnsParams = {
  openRemoveModal?: RemoveDialogParamsSetter;
};

export const getColumns: (params?: GetColumnsParams) => GridColumns = ({
  openRemoveModal,
} = {}) =>
  injectStylesToOperators(
    compact([
      {
        field: 'meta.schema_queues',
        filterOperators: queuesOperators,
        flex: 2.6,
        headerName: 'Queue',
        minWidth: 260,
        renderCell: renderQueuesLinks,
        sortComparator: queuesComparator,
        valueGetter: getFieldValue,
      },
      {
        editable: true,
        field: 'label',
        flex: 2,
        headerName: 'Label',
        minWidth: 200,
        preProcessEditCellProps: validateNonEmptyString('Label'),
        renderEditCell: EditCellWithValidation,
      },
      {
        editable: false,
        field: 'category',
        flex: 1.2,
        headerName: 'Category',
        minWidth: 120,
        renderCell: renderSingleSelectCell,
        type: 'singleSelect',
        valueGetter: getFieldValue,
        valueOptions: categoryOptions,
      },
      {
        editabilityCondition: isDatapoint,
        editable: true,
        field: 'type',
        flex: 1.2,
        headerName: 'Data type',
        minWidth: 120,
        renderCell: renderSingleSelectCell,
        type: 'singleSelect',
        valueGetter: getFieldValue,
        valueOptions: typeOptions,
        valueSetter: params => {
          const rowWithNewValue = {
            ...params.row,
            type: params.value,
            ...(params.value === 'button'
              ? { rir_field_names: null }
              : {
                  rir_field_names:
                    get(params.row, 'rir_field_names') ||
                    get(params.row.meta.original, 'rir_field_names') ||
                    [],
                }),
            ...(params.value === 'enum'
              ? {
                  options:
                    get(params.row, 'options') ||
                    get(params.row.meta.original, 'options') ||
                    [],
                }
              : {}),
          };

          return {
            ...rowWithNewValue,
            meta: {
              ...rowWithNewValue.meta,
              type: getFieldType(rowWithNewValue),
            },
          };
        },
      },
      {
        editabilityCondition: isNotButtonHeaderDatapoint,
        editable: true,
        field: 'ui_configuration.type',
        flex: 1.2,
        headerName: 'Field type',
        minWidth: 120,
        renderCell: renderSingleSelectCell,
        type: 'singleSelect',
        valueGetter: getFieldValue,
        valueOptions: uiConfigurationTypeOptions,
      },
      {
        editabilityCondition: isNumberOrDate,
        editable: true,
        field: 'format',
        flex: 2,
        headerName: 'Format',
        minWidth: 200,
        renderEditCell: renderFormatEditCell,
        valueGetter: getFieldValue,
        valueSetter: ({ row, value }) => {
          const newValue =
            typeof value === 'object' && value !== null && 'value' in value
              ? value.value
              : value;

          return {
            ...row,
            format: newValue,
          };
        },
      },
      {
        editabilityCondition: isNotButtonHeaderDatapoint,
        editable: true,
        field: 'ui_configuration.edit',
        flex: 1.6,
        headerName: 'Editing',
        minWidth: 160,
        renderCell: renderSingleSelectCell,
        type: 'singleSelect',
        valueGetter: getFieldValue,
        valueOptions: uiConfigurationEditOptions,
      },
      {
        editabilityCondition: isNotButtonDatapoint,
        editable: true,
        field: 'score_threshold',
        flex: 1.4,
        headerName: 'Score threshold',
        minWidth: 140,
        preProcessEditCellProps: validatePercentageRange('Score threshold'),
        renderEditCell: EditCellWithValidation,
        type: 'number',
        valueFormatter: appendPercentSignToNumber,
        valueGetter: p => {
          const value: null | number | undefined = getFieldValue(p);

          return valueToPercentage(value);
        },
        valueSetter: <T,>(params: { row: T; value: number | null }) => {
          const { value } = params;

          const score_threshold = percentageToValue(value);

          return {
            ...params.row,
            score_threshold,
          };
        },
      },
      {
        editabilityCondition: isNotButtonDatapoint,
        editable: true,
        field: 'constraints.required',
        flex: 1,
        headerName: 'Required',
        minWidth: 100,
        renderCell: renderBooleanCell,
        type: 'boolean',
        valueGetter: getFieldValue,
      },
      {
        editabilityCondition: isNotTuple,
        editable: true,
        field: 'hidden',
        flex: 1,
        headerName: 'Visible',
        minWidth: 100,
        renderCell: renderBooleanCell,
        type: 'boolean',
        valueGetter: negate(getFieldValue),
        valueSetter: params => ({ ...params.row, hidden: !params.value }),
      },
      {
        editabilityCondition: isNotTupleNorSection,
        editable: true,
        field: 'can_export',
        flex: 1,
        headerName: 'Export',
        minWidth: 100,
        renderCell: renderBooleanCell,
        type: 'boolean',
        valueGetter: getFieldValue,
      },
      {
        editabilityCondition: canHaveRirFieldNames,
        editable: true,
        field: 'rir_field_names',
        flex: 2.4,
        headerName: 'RIR field names',
        minWidth: 240,
        renderCell: renderChipsCell,
        renderEditCell: renderMultipleEditAutocomplete,
        sortComparator: rirFieldNamesComparator,
        valueGetter: getFieldValue,
        valueSetter: ({ row, value }) => {
          const newValue =
            isEqual(value, []) &&
            ('rir_field_names' in row.meta.original &&
              row.meta.original.rir_field_names) === null
              ? null
              : value;

          return {
            ...row,
            rir_field_names: newValue,
          };
        },
      },
      {
        editabilityCondition: isNotTupleNorSection,
        editable: true,
        field: 'disable_prediction',
        flex: 1.6,
        headerName: 'Disable prediction',
        minWidth: 160,
        renderCell: renderBooleanCell,
        type: 'boolean',
        valueGetter: getFieldValue,
      },
      {
        editabilityCondition: isDatapointInTable,
        editable: true,
        field: 'can_collapse',
        flex: 1.2,
        headerName: 'Can collapse',
        minWidth: 120,
        renderCell: renderBooleanCell,
        type: 'boolean',
        valueGetter: getFieldValue,
      },
      {
        editabilityCondition: isNotButtonDatapoint,
        editable: true,
        field: 'default_value',
        flex: 2,
        headerName: 'Default value',
        minWidth: 200,
        valueGetter: getFieldValue,
        valueSetter: ({ row, value }) => {
          const newValue =
            value === '' &&
            ('default_value' in row.meta.original &&
              row.meta.original.default_value) === null
              ? null
              : value;

          return {
            ...row,
            default_value: newValue,
          };
        },
      },
      {
        editabilityCondition: isDatapointInTable,
        editable: true,
        field: 'width',
        flex: 0.8,
        headerName: 'Width',
        minWidth: 80,
        preProcessEditCellProps: validatePositiveInteger('Width'),
        renderEditCell: EditCellWithValidation,
        type: 'number',
        valueGetter: getFieldValue,
        valueSetter: ({ row, value }) => ({
          ...row,
          width: value === undefined ? null : value,
        }),
      },
      {
        editabilityCondition: isDatapointInTable,
        editable: true,
        field: 'stretch',
        flex: 1,
        headerName: 'Stretch',
        minWidth: 100,
        renderCell: renderBooleanCell,
        type: 'boolean',
        valueGetter: getFieldValue,
      },
      openRemoveModal && {
        field: 'actions',
        getActions: ({ row }: GridRowParams<GridRowModel>) => [
          <GridActionsCellItem
            icon={<DeleteOutline />}
            label="Remove"
            onClick={() => openRemoveModal({ selectedRows: [row] })}
            data-cy="fm-remove-field-action"
            showInMenu
          />,
        ],
        resizable: false,
        type: 'actions',
        width: 50,
      },
    ])
  );

export const columsMap = fromPairs(getColumns().map(c => [c.field, c]));
