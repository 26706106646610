import {
  GridCell,
  GridCellProps,
  GridValidRowModel,
  GridValueSetterParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useEffect } from 'react';

import { aggregationRow } from '../constants';
import { formatAggregate } from './aggregations';
import { columsMap } from './columns/columns';

const BatchEditCellStateHandler = ({
  setBatchEditError,
  ...props
}: Omit<GridCellProps, 'editCellState'> & {
  editCellState: NonNullable<GridCellProps['editCellState']>;
  setBatchEditError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const apiRef = useGridApiContext();
  const { editCellState, field } = props;
  const { value, changeReason, error } = editCellState;
  const hasChanged = !!changeReason;

  useEffect(() => {
    if (hasChanged) {
      const selectedRows = Array.from(
        apiRef.current.getSelectedRows().values()
      );

      const columnValueSetter = columsMap[field]?.valueSetter;
      const defaultValueSetter = (
        params: GridValueSetterParams<GridValidRowModel>
      ) => ({
        ...params.row,
        [field]: params.value,
      });
      const fieldValueSetter = columnValueSetter ?? defaultValueSetter;

      const updatedRows = selectedRows.map(row =>
        // @ts-expect-error GridValidRowModel returned from getSelectedRows is less strict version of GridRowModel
        fieldValueSetter({ row, value })
      );

      apiRef.current.updateRows(updatedRows);
      setBatchEditError(!!error);
    }
  }, [value, hasChanged, apiRef, field, error, setBatchEditError]);

  return <GridCell {...props} />;
};

export const CustomGridCell = ({
  selectionActive,
  setBatchEditError,
  editCellState,
  ...props
}: GridCellProps & {
  selectionActive: boolean;
  setBatchEditError: React.Dispatch<React.SetStateAction<boolean>>;
}) =>
  editCellState && selectionActive ? (
    <BatchEditCellStateHandler
      {...props}
      editCellState={editCellState}
      setBatchEditError={setBatchEditError}
    />
  ) : (
    <GridCell {...props} editCellState={editCellState}>
      {props.rowId === aggregationRow
        ? formatAggregate(props.field, props.value)
        : props.children}
    </GridCell>
  );
