import {
  getGridStringOperators,
  GridColDef,
  GridFilterItem,
} from '@mui/x-data-grid-pro';

import { Queue } from '../../types/queue';

export const queuesOperators = getGridStringOperators()
  .filter(
    operator =>
      !['equals', 'isAnyOf', 'endsWith', 'isEmpty', 'isNotEmpty'].includes(
        operator.value
      )
  )
  .map(operator => ({
    ...operator,
    getApplyFilterFnV7: (filterItem: GridFilterItem, column: GridColDef) => {
      const filterFunction =
        operator.getApplyFilterFnV7?.(filterItem, column) ?? null;

      return filterFunction === null
        ? null
        : (v: Array<Queue>): boolean => {
            const value = Array.isArray(v)
              ? v.map(({ name }) => name).join(' ')
              : null;

            // @ts-expect-error default string operators expect only first argument
            return filterFunction(value);
          };
    },
  }));
