import { Link } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { DataForQueuesDialog } from './QueuesDialog';

export const QueuesDialogLink =
  (
    setRowForQueuesDialog: React.Dispatch<
      React.SetStateAction<DataForQueuesDialog | null>
    >
  ) =>
  ({ formattedValue, row }: GridRenderCellParams) =>
    (
      <Link
        component="button"
        color="secondary"
        data-cy="fm-queues-dialog-link"
        onClick={() =>
          setRowForQueuesDialog({ fieldId: row.fieldId, queues: row.queues })
        }
      >
        {formattedValue}
      </Link>
    );
