import { ComponentProps } from 'react';

import { DataType } from '../rows';
import { OptionWithHelpText } from './types';
import {
  EditCellAutocomplete,
  renderOptionWithHelpText,
} from './ui/EditCellAutocomplete';

const numberFormatOptions = [
  { label: '# ##0,#', helpText: '1 230,0', value: '# ##0,#' },
  { label: '# ##0.#', helpText: '1 230.0', value: '# ##0.#' },
  { label: '#,##0.#', helpText: '1,230.0', value: '#,##0.#' },
  { label: `#'##0.#`, helpText: `1'230.0`, value: `#'##0.#` },
  { label: `#.##0,#`, helpText: `1.230,0`, value: `#.##0,#` },
  { label: '# ##0', helpText: '1 230', value: '# ##0' },
  { label: '#,##0', helpText: '1,230', value: '#,##0' },
  { label: `#'##0`, helpText: `1'230`, value: `#'##0` },
  { label: `#.##0`, helpText: `1.230`, value: `#.##0` },
];

const dateFormatOptions = [
  { label: 'D/M/YYYY', helpText: '23/1/2019', value: 'D/M/YYYY' },
  { label: 'MM/DD/YYYY', helpText: '01/23/2019', value: 'MM/DD/YYYY' },
  { label: 'YYYY-MM-DD', helpText: '2019-01-23', value: 'YYYY-MM-DD' },
  { label: 'DD.MM.YY', helpText: '23.01.19', value: 'DD.MM.YY' },
];

const options: Partial<Record<DataType, Array<OptionWithHelpText>>> = {
  date: dateFormatOptions,
  number: numberFormatOptions,
};

const getOptions = (type: DataType) => options[type] ?? [];

export const renderFormatEditCell = (
  params: ComponentProps<
    typeof EditCellAutocomplete<OptionWithHelpText | string>
  >
) => (
  <EditCellAutocomplete<OptionWithHelpText | string>
    {...params}
    AutocompleteProps={{
      options: getOptions(params.row.meta.type),
      renderOption: renderOptionWithHelpText,
      isOptionEqualToValue: (option, currentValue) =>
        (typeof option === 'string' ? option : option.value) === currentValue,
    }}
  />
);
