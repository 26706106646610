import { ArrowBack } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

import { FieldManagerRoute } from '../routes';

type Props = PropsWithChildren<{
  route: FieldManagerRoute;
  onArrowBackClick?: () => void;
}>;

export const Header = ({ children, route, onArrowBackClick }: Props) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    sx={{ mt: -1 }}
  >
    <Stack
      direction="row"
      alignItems="center"
      sx={{ minHeight: 40 }}
      spacing={1}
    >
      {onArrowBackClick && (
        <IconButton onClick={onArrowBackClick} data-cy="fm-back-arrow">
          <ArrowBack />
        </IconButton>
      )}
      <Typography variant="h5">
        {route.path === 'field-manager' ? 'Field manager' : route.schema_id}
      </Typography>
    </Stack>
    {children}
  </Stack>
);
