import { Link, Stack, Typography } from '@mui/material';

type LinkWithNameAndIdProps = {
  link: string;
  name: string;
  id: string;
};

export const LinkWithNameAndId = ({
  link,
  name,
  id,
}: LinkWithNameAndIdProps) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <Typography
      variant="body1"
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      <Link
        color="secondary"
        target="_blank"
        rel="noreferrer noopener"
        href={link}
      >
        {name}
      </Link>
    </Typography>
    <Typography
      variant="body2"
      sx={{
        whiteSpace: 'nowrap',
        color: theme => theme.palette.text.secondary,
      }}
    >
      (id {id})
    </Typography>
  </Stack>
);
