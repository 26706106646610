import { z } from 'zod';

const windowEnvSchema = z.object({
  ENVIRONMENT: z.string().optional(),
  SENTRY_DSN: z.string().optional(),
  SENTRY_ENABLED: z.boolean().optional(),
  SENTRY_ENVIRONMENT: z.string().optional(),
  TRACKING_ENABLED: z.boolean().optional(),
  SMARTLOOK_ENABLED: z.boolean().optional(),
  SMARTLOOK_PROJECT_ID: z.string().optional(),
});

export type ENV_VARIABLES = z.infer<typeof windowEnvSchema>;
type ENV_VARIABLE = keyof ENV_VARIABLES;

const parsedWindowEnv = windowEnvSchema.catch({}).parse(window.env ?? {});
const parsedLocalOverrides = (() => {
  // JSON.parse can fail thus we have this try/catch block
  try {
    return windowEnvSchema
      .catch({})
      .parse(
        JSON.parse(localStorage.getItem('localConfigOverrides') || '') ?? {}
      );
  } catch {
    return {};
  }
})();

const getLocalOverride = <T extends ENV_VARIABLE>(
  name: T
): ENV_VARIABLES[T] | null =>
  parsedLocalOverrides[name] === undefined ? null : parsedLocalOverrides[name];

const resolveConfigValue = <T extends ENV_VARIABLE>(
  variableName: T
): ENV_VARIABLES[T] =>
  getLocalOverride(variableName) ?? parsedWindowEnv[variableName];

const configSchema = z.object({
  environment: z.string().default('unknown'),
  sentryDsn: z.string().optional(),
  sentryEnabled: z.boolean().default(false),
  sentryEnvironment: z.string().default('unknown'),
  trackingEnabled: z.boolean().default(false),
  smartlookEnabled: z.boolean().default(false),
  smartlookProjectId: z.string().optional(),
  codeVersion: z.string().default(''),
});

const configObj: Partial<z.infer<typeof configSchema>> = {
  environment: resolveConfigValue('ENVIRONMENT'),
  sentryDsn: resolveConfigValue('SENTRY_DSN'),
  sentryEnabled: resolveConfigValue('SENTRY_ENABLED'),
  sentryEnvironment: resolveConfigValue('SENTRY_ENVIRONMENT'),
  trackingEnabled: resolveConfigValue('TRACKING_ENABLED'),
  smartlookEnabled: resolveConfigValue('SMARTLOOK_ENABLED'),
  smartlookProjectId: resolveConfigValue('SMARTLOOK_PROJECT_ID'),
  codeVersion: document.querySelector<HTMLMetaElement>(
    'meta[name="GIT_COMMIT"]'
  )?.content,
};

export const config = configSchema.parse(configObj);

const DEV_FEATURES_ENABLED =
  window.localStorage.getItem('devFeaturesEnabled') || false;

if (DEV_FEATURES_ENABLED) {
  // eslint-disable-next-line no-console
  console.log({ parsedLocalOverrides, parsedWindowEnv, config });
}
