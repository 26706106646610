export const lengthComparator = (
  value1: Array<unknown> | undefined = [],
  value2: Array<unknown> | undefined = []
) => value1.length - value2.length;

export const arrayStringComparator = (
  value1: Array<string> | undefined = [],
  value2: Array<string> | undefined = []
) => {
  if (value1.length === 1 && value2.length === 1) {
    return (value1[0] ?? '').localeCompare(value2[0] ?? '');
  }

  if (value1.length === 1 || value2.length === 0) {
    return -1;
  }

  if (value2.length === 1 || value1.length === 0) {
    return 1;
  }

  return value1.length - value2.length;
};
