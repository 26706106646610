import { GridSortCellParams } from '@mui/x-data-grid-pro';

import { Queue } from '../../../types/queue';
import { text } from '../../constants';

export const queuesComparator = (
  value1: Array<Queue> | Set<number>,
  value2: Array<Queue> | Set<number>,
  row1: GridSortCellParams,
  row2: GridSortCellParams
) => {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length === 1 && value2.length === 1) {
      return (value1[0]?.name ?? '').localeCompare(value2[0]?.name ?? '');
    }

    if (value1.length === 1) {
      return -1;
    }

    if (value2.length === 1) {
      return 1;
    }

    return Number(row1.id) - Number(row2.id);
  }

  return 0;
};

type RirFieldValue = null | typeof text.cellNotEditable | Array<string>;

export const rirFieldNamesComparator = (
  value1: RirFieldValue,
  value2: RirFieldValue
) => {
  if (value1 === text.cellNotEditable) {
    return 1;
  }

  if (value2 === text.cellNotEditable) {
    return -1;
  }

  return (value1 ?? []).join(' ').localeCompare((value2 ?? []).join(' '));
};
