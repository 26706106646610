import { Edit } from '@mui/icons-material';
import { Button, Menu, MenuItem, Stack } from '@mui/material';
import { useRef, useState } from 'react';

import { GridColumns } from '../columns/types';

type EditValueProps = {
  editableColumns: Array<GridColumns[number] & { isBatchEditable: boolean }>;
  onEditColumnValueClick: (field: string) => void;
};

export const EditValue = ({
  editableColumns,
  onEditColumnValueClick,
}: EditValueProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack>
      <Button
        color="secondary"
        ref={anchorRef}
        onClick={handleToggle}
        startIcon={<Edit />}
        data-cy="fm-batch-edit-value-button"
      >
        Edit value
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        MenuListProps={{ dense: true }}
        sx={{ maxHeight: 345 }}
      >
        {editableColumns.map(column => (
          <MenuItem
            disabled={!column.isBatchEditable}
            key={column.field}
            data-cy={`fm-edit-value-item-${column.field}`}
            onClick={() => {
              handleClose();
              onEditColumnValueClick(column.field);
            }}
          >
            {column.headerName}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};
