/* eslint-disable no-param-reassign */
export const initGTM = () => {
  window.dataLayer = window.dataLayer || [];
  ((w, _d, _s, l, _i) => {
    // @ts-expect-error ignoring as the code is provided by GTM
    w[l] = w[l] || [];
    // @ts-expect-error ignoring as the code is provided by GTM
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  })(window, document, 'script', 'dataLayer', 'GTM-ML6R7M5');

  const gtm = document.createElement('script');
  gtm.async = true;
  gtm.defer = true;
  gtm.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-ML6R7M5';
  document.head.appendChild(gtm);
};
