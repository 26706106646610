import {
  MessageMethodCalls,
  rossumRpcMessageType,
  RPCResponse,
} from './rossumMessagesTypes';

export let referrerTargetOrigin = '';
try {
  const { origin } = new URL(document.referrer);
  // bypass dev mode hot reload that sets document.referrer origin to current window origin
  if (origin !== window.location.origin) {
    referrerTargetOrigin = origin;
  }
} catch (_) {}

// TODO: use uuid v4
export const generateCallId = () => Math.random().toString(36).substr(2);

export const rossumMessage = <T = unknown>(...args: MessageMethodCalls) => {
  const [methodName, ...params] = args;

  return new Promise<RPCResponse<T>>((resolve, reject) => {
    const callId = generateCallId();
    const handler = ({ data, origin }: MessageEvent) => {
      if (
        origin !== referrerTargetOrigin ||
        !(callId === data.callId && data.type === rossumRpcMessageType)
      ) {
        return;
      }

      const typedData: RPCResponse<T> = data;

      window.removeEventListener('message', handler);
      return data.methodResponse.value ? resolve(typedData) : reject(typedData);
    };

    window.addEventListener('message', handler);
    window.parent.postMessage(
      {
        type: rossumRpcMessageType,
        callId,
        methodCall: { methodName, params },
      },
      referrerTargetOrigin
    );
  });
};
