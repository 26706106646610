import { alpha, darken, lighten, SxProps, Theme } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid-pro';

import { text } from '../constants';

export const cellEditabilityDisabled = 'edit-disabled';

export const batchEditingActive = 'batch-editing-active';

export const rowHasError = 'row-has-error';

export const dataGridStyles: SxProps<Theme> = {
  [`& .${cellEditabilityDisabled}`]: {
    color: 'text.disabled', // matching grey with 'Not set' for '-'
    cursor: 'not-allowed',
  },
  [`& .${gridClasses.pinnedRows}`]: {
    boxShadow: 'none',
    [`.${gridClasses.cell}`]: {
      border: 'none',

      borderTop: theme =>
        theme.palette.mode === 'light'
          ? `1px solid ${lighten(alpha(theme.palette.divider, 1), 0.88)}`
          : `1px solid ${darken(alpha(theme.palette.divider, 1), 0.68)}`,

      backgroundColor: theme => theme.palette.background.default,
    },
  },
  [`& .${gridClasses.pinnedColumns}, .${gridClasses.pinnedColumnHeaders}`]: {
    boxShadow: 'none',
    borderLeft: theme =>
      theme.palette.mode === 'light'
        ? `1px solid ${lighten(alpha(theme.palette.divider, 1), 0.88)}`
        : `1px solid ${darken(alpha(theme.palette.divider, 1), 0.68)}`,

    backgroundImage: 'none',
    [`.${gridClasses.cell}`]: {
      backgroundColor: theme => theme.palette.background.default,
    },
  },
  [`& .${gridClasses.booleanCell}[data-value='false']`]: {
    color: theme => theme.palette.text.secondary, // Not matching grid style in GridRootStyles.js, but keeping original color in dark mode
  },
  [`& .${gridClasses.cellContent}:empty:after, .singleSelectCellContent:empty:before`]:
    {
      color: 'text.disabled',
      content: `'${text.cellNotSet}'`,
    },
  [`&& .${gridClasses.cell}.${gridClasses['cell--editing']}`]: {
    background: theme => theme.palette.action.selected,
    border: theme => `1px solid ${theme.palette.primary.main}`,
  },
  [`& .${batchEditingActive}`]: {
    background: theme => alpha(theme.palette.action.selected, 0.08),
    [`.${gridClasses.cellContent}: empty: after`]: {
      content: `''`,
    },
  },
  [`&& .${rowHasError} `]: {
    backgroundColor: theme => alpha(theme.palette.error.main, 0.1),
    '&:hover': {
      background: theme => alpha(theme.palette.error.main, 0.15),
    },
  },
};
