import { Stack, Typography } from '@mui/material';
import { fromPairs } from 'lodash';
import { useMemo } from 'react';

import { ResultsPerStatus } from '../../../hooks/usePatchSchemas';
import { FlatSchemaWithQueues } from '../../../types/schema';
import { resolveQueuesDisplayedValue } from '../columns/filterOperators';
import { collectStringArrays } from './collectStringArrays';
import { ErrorKey } from './ErrorKey';

type Props = {
  rejectedRequests: ResultsPerStatus['rejected'];
  schemas: FlatSchemaWithQueues[];
};

type ErrorShape = {
  url: string;
  error: { response: Record<string, unknown> };
};

export const ErrorDialogContent = ({ rejectedRequests, schemas }: Props) => {
  const rejectedSchemaErrors = rejectedRequests.filter(
    (rejected): rejected is ErrorShape =>
      'url' in rejected && 'error' in rejected && 'response' in rejected.error
  );

  const schemasUrlMap = useMemo(
    () => fromPairs(schemas.map(schema => [schema.url, schema])),
    [schemas]
  );

  return (
    <Stack spacing={1}>
      <Typography variant="body2">
        There was an error while saving changes in:
      </Typography>
      <Stack spacing={2}>
        {rejectedSchemaErrors.map(({ url, error: { response } }) => {
          const schema = schemasUrlMap[url];
          if (!schema) {
            return null;
          }

          const errors = collectStringArrays(response);

          return (
            <Stack key={url}>
              <Typography sx={{ fontWeight: 'bold' }} key={url}>
                {resolveQueuesDisplayedValue(schema.queues, schema.id)}
              </Typography>
              {errors.map(error =>
                Object.entries(error).map(([key, value]) => (
                  <div key={key}>
                    <ErrorKey pathKey={key} />{' '}
                    <Typography component="span" variant="body2">
                      {value.join(', ')}
                    </Typography>
                  </div>
                ))
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
