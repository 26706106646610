import { useEffect } from 'react';

import { pushToDataLayer } from '../../tracking';
import { FieldManagerRoute } from './field-manager/routes';

export const useTrackFieldManagerView = (path: FieldManagerRoute['path']) => {
  useEffect(() => {
    pushToDataLayer({
      event: 'field-manager-view',
      view: path,
    });
  }, [path]);
};
