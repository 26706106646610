import {
  Autocomplete,
  ListItem,
  ListItemText,
  outlinedInputClasses,
  TextField,
  Typography,
} from '@mui/material';
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { ComponentProps } from 'react';

import { GridRowModel } from '../../rows';
import { OptionWithHelpText } from '../types';

type EditCellAutocompleteProps<
  T,
  R extends GridRowModel = GridRowModel
> = GridRenderEditCellParams<R> & {
  AutocompleteProps?: Partial<
    ComponentProps<typeof Autocomplete<T, boolean | undefined, true, true>>
  >;
};

export const EditCellAutocomplete = <T, R extends GridRowModel = GridRowModel>(
  props: EditCellAutocompleteProps<T, R>
) => {
  const {
    id,
    value,
    field,
    AutocompleteProps: { multiple, options, ...rest } = {},
  } = props;
  const apiRef = useGridApiContext();

  const valueWithFallback = value ?? (multiple ? [] : '');

  return (
    <Autocomplete
      multiple={multiple}
      freeSolo
      onChange={(e, newValue) => {
        if (multiple) {
          e.stopPropagation();
        }

        apiRef.current.setEditCellValue({ id, field, value: newValue });
      }}
      sx={{ width: '100%' }}
      disableClearable
      value={valueWithFallback}
      options={options ?? []}
      renderInput={params => (
        <TextField
          {...params}
          autoFocus
          fullWidth
          InputProps={{
            ...params.InputProps,
            sx: {
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                border: 'unset !important',
              },
            },
          }}
        />
      )}
      {...rest}
    />
  );
};

type RenderOption = Required<
  ComponentProps<typeof Autocomplete<OptionWithHelpText | string>>
>['renderOption'];

export const renderOptionWithHelpText: RenderOption = (props, option) =>
  typeof option === 'string' ? (
    <ListItem {...props} dense>
      <ListItemText>{option}</ListItemText>
    </ListItem>
  ) : (
    <ListItem
      {...props}
      dense
      secondaryAction={
        <Typography variant="caption" sx={{ opacity: 0.5 }}>
          {option.helpText}
        </Typography>
      }
    >
      <ListItemText>{option.label}</ListItemText>
    </ListItem>
  );

export const renderMultipleEditAutocomplete = <T,>(
  params: EditCellAutocompleteProps<T>
) => (
  <EditCellAutocomplete {...params} AutocompleteProps={{ multiple: true }} />
);
