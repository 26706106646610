import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { groupBy, some, sortBy } from 'lodash';
import { useMemo } from 'react';

import { referrerTargetOrigin } from '../../../../lib/rossumMessage';
import { useGetWorkspaces } from '../../hooks/useGetWorkspaces';
import { Queue } from '../../types/queue';
import { Workspace } from '../../types/workspace';
import { Dialog } from '../ui/Dialog';
import { LinkWithNameAndId } from '../ui/LinkWithNameAndId';

export type DataForQueuesDialog = {
  fieldId: string;
  queues: Queue[];
};

type QueueDialogProps = {
  rowForQueuesDialog: DataForQueuesDialog;
  setRowForQueuesDialog: React.Dispatch<
    React.SetStateAction<DataForQueuesDialog | null>
  >;
};

export const QueuesDialog = ({
  rowForQueuesDialog,
  setRowForQueuesDialog,
}: QueueDialogProps) => {
  const workspaceQueries = useGetWorkspaces();
  const workspaces: Workspace[] = useMemo(
    () => workspaceQueries.data?.pages.flatMap(page => page.results) || [],
    [workspaceQueries.data]
  );
  const sortedNonEmptyWorkspaces = sortBy(workspaces, 'name').filter(
    workspace =>
      some(
        rowForQueuesDialog.queues,
        queue => queue.workspace === workspace.url
      )
  );
  const queuesByWorkspace = groupBy(rowForQueuesDialog.queues, 'workspace');

  return (
    <Dialog
      onClose={() => setRowForQueuesDialog(null)}
      open
      title={`Queues for ${rowForQueuesDialog.fieldId}`}
    >
      {sortedNonEmptyWorkspaces.map(workspace => (
        <Accordion key={workspace.id} defaultExpanded elevation={4}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">{workspace.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {sortBy(queuesByWorkspace[workspace.url] || [], 'name').map(
              queue => (
                <LinkWithNameAndId
                  link={`${referrerTargetOrigin}/settings/queues/${queue.id}/fields`}
                  name={queue.name}
                  id={String(queue.id)}
                  key={queue.id}
                />
              )
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Dialog>
  );
};
