import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-pro';

import { text } from '../constants';
import {
  categoryOptions,
  FieldOptions,
  typeOptions,
  uiConfigurationEditOptions,
  uiConfigurationTypeOptions,
} from '../data/options';
import { FieldManagerRoute } from '../routes';
import { injectStylesToOperators } from '../ui/dataGridStyles';
import { FieldDetailLink } from './FieldDetailLink';
import { queuesOperators } from './filterOperators';
import { DataForQueuesDialog } from './QueuesDialog';
import { QueuesDialogLink } from './QueuesDialogLink';
import { arrayStringComparator, lengthComparator } from './sortComparators';

const arrayValueFormatter =
  (singularText: string, pluralText: string) =>
  (params: GridValueFormatterParams) => {
    if (params.value) {
      const postfix = params.value.length === 1 ? singularText : pluralText;
      return `${params.value.length} ${postfix}`;
    }
    return text.cellNotEditable;
  };

const getOptionLabel = (options: FieldOptions, value: string) =>
  options.find(option => option.value === value)?.label || '';

const optionValueFormatter =
  (options: FieldOptions) => (params: GridValueFormatterParams) => {
    if (params.value && Array.isArray(params.value)) {
      if (params.value.length > 1) {
        return `${params.value.length} values`;
      }
      return getOptionLabel(options, String(params.value[0]));
    }
    return params.value === undefined
      ? text.cellNotEditable
      : getOptionLabel(options, String(params.value));
  };

type Props = {
  setRoute: (route: FieldManagerRoute) => void;
  setRowForQueuesDialog: React.Dispatch<
    React.SetStateAction<DataForQueuesDialog | null>
  >;
};

export const getColumns = ({
  setRoute,
  setRowForQueuesDialog,
}: Props): GridColDef[] =>
  injectStylesToOperators(
    [
      {
        field: 'fieldId',
        headerName: 'Field ID',
        minWidth: 140,
        flex: 1.4,
        renderCell: FieldDetailLink(setRoute),
      },
      {
        field: 'queues',
        headerName: 'Queues',
        minWidth: 100,
        flex: 1,
        sortComparator: lengthComparator,
        valueFormatter: arrayValueFormatter('queue', 'queues'),
        filterOperators: queuesOperators,
        renderCell: QueuesDialogLink(setRowForQueuesDialog),
      },
      {
        field: 'label',
        headerName: 'Label',
        minWidth: 140,
        flex: 1.4,
      },
      {
        field: 'category',
        headerName: 'Category',
        minWidth: 100,
        flex: 1,
        valueFormatter: optionValueFormatter(categoryOptions),
      },
      {
        field: 'dataType',
        headerName: 'Data type',
        minWidth: 100,
        flex: 1,
        valueFormatter: optionValueFormatter(typeOptions),
      },
      {
        field: 'fieldType',
        headerName: 'Field type',
        minWidth: 100,
        flex: 1,
        valueFormatter: optionValueFormatter(uiConfigurationTypeOptions),
      },
      {
        field: 'editing',
        headerName: 'Editing',
        minWidth: 100,
        flex: 1,
        valueFormatter: optionValueFormatter(uiConfigurationEditOptions),
      },
      {
        field: 'rirFieldNames',
        headerName: 'RIR field names',
        minWidth: 120,
        flex: 1.2,
        valueFormatter: arrayValueFormatter('value', 'values'),
        sortComparator: lengthComparator,
      },
    ].map(column => ({
      ...column,
      valueFormatter:
        column.valueFormatter ??
        ((params: GridValueFormatterParams) => {
          if (params.value && Array.isArray(params.value)) {
            if (params.value.length > 1) {
              return `${params.value.length} values`;
            }
            return String(params.value[0]);
          }
          return params.value === undefined
            ? text.cellNotEditable
            : String(params.value);
        }),
      sortComparator: column.sortComparator ?? arrayStringComparator,
    }))
  );
