import {
  FormControl,
  inputBaseClasses,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import {
  Controller,
  FieldErrors,
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { SFTPExtension } from '../types';
import { ErrorMessage } from './ErrorMessage';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Pick<
  UseControllerProps<TFieldValues, TName>,
  'name' | 'control' | 'defaultValue'
> & {
  label: string;
  labelId: string;
  dropdownList: readonly string[];
  onChange?: (e: SelectChangeEvent) => void;
  errors?: FieldErrors<SFTPExtension>;
};

export const Dropdown = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  defaultValue,
  label,
  labelId,
  dropdownList,
  onChange,
  errors,
}: Props<TFieldValues, TName>) => {
  const {
    fieldState: { error },
  } = useController({ control, name });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange: onFieldChange, ref, ...rest } }) => (
        <Stack width={1}>
          <FormControl
            sx={{
              ...(error && {
                [`& .${inputBaseClasses.root}`]: {
                  '& fieldset': {
                    borderColor: 'error.main',
                  },
                },
              }),
            }}
            fullWidth
          >
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
              labelId={labelId}
              input={<OutlinedInput label={label} />}
              value={value ?? defaultValue}
              onChange={onChange ?? onFieldChange}
              inputRef={ref}
              sx={{ height: 34.6 }}
              {...rest}
            >
              {dropdownList.map(dropdownValue => (
                <MenuItem key={uuidv4()} value={dropdownValue}>
                  {dropdownValue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors && <ErrorMessage errors={errors} name={name} />}
        </Stack>
      )}
      defaultValue={defaultValue}
    />
  );
};
