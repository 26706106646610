import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import MissingReferrerCheck from './components/MissingReferrerAlert';
import { config } from './config';
import { initGTM } from './gtm';
import ThemeProvider from './ThemeProvider';

Sentry.init({
  dsn: config.sentryDsn,
  attachStacktrace: true,
  enabled: config.sentryEnabled,
  environment: config.sentryEnvironment,
  release: config.codeVersion,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 0,
});

const root = document.getElementById('root');

if (config.trackingEnabled) {
  initGTM();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

if (root) {
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <ThemeProvider>
        <MissingReferrerCheck>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools position="bottom-right" />
          </QueryClientProvider>
        </MissingReferrerCheck>
      </ThemeProvider>
    </React.StrictMode>
  );
}
