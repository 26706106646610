import { Alert, Typography } from '@mui/material';
import { useState } from 'react';

import { ResultsPerStatus } from '../../../hooks/usePatchSchemas';
import { FlatSchemaWithQueues } from '../../../types/schema';
import { Dialog } from '../../ui/Dialog';
import { ErrorDialogContent } from './ErrorDialogContent';

type Props = {
  rejectedRequests?: ResultsPerStatus['rejected'];
  schemas: FlatSchemaWithQueues[];
};

export const ErrorMessageWithDialog = ({
  schemas,
  rejectedRequests,
}: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <Alert variant="filled" severity="error">
      <Typography variant="body2">
        There was an error while saving changes.
        {rejectedRequests && rejectedRequests.length !== 0 && (
          <>
            {' '}
            <Typography
              component="span"
              variant="body2"
              onClick={() => setOpen(true)}
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              More details
            </Typography>
            <Dialog
              title="Error details"
              open={open}
              onClose={() => setOpen(false)}
            >
              <ErrorDialogContent
                rejectedRequests={rejectedRequests}
                schemas={schemas}
              />
            </Dialog>
          </>
        )}
      </Typography>
    </Alert>
  );
};
