import { CssBaseline, ThemeProvider as MUIThemeProvider } from '@mui/material';
import { resolveTheme } from '@rossum/rossum-ui/theme';
import React, { useEffect, useMemo, useState } from 'react';

import { referrerTargetOrigin, rossumMessage } from './lib/rossumMessage';
import { GetUiSettingsResponse } from './lib/rossumMessagesTypes';

type Props = {
  children: React.ReactNode;
};

function ThemeProvider({ children }: React.PropsWithChildren<Props>) {
  const [uiSettings, setUiSettings] = useState<GetUiSettingsResponse | null>(
    null
  );

  useEffect(() => {
    if (!referrerTargetOrigin) {
      return setUiSettings({ theme: 'dark', locale: 'en' });
    }
    rossumMessage<GetUiSettingsResponse>('ConfigApp.getUiSettings').then(
      ({ methodResponse: { value } }) => {
        setUiSettings(value);
      }
    );
  }, []);

  const theme = useMemo(() => {
    const currentTheme = uiSettings?.theme === 'white' ? 'light' : 'dark';

    return resolveTheme(currentTheme, {
      palette: uiSettings?.branding?.colors?.primary
        ? {
            primary: {
              main: uiSettings.branding.colors.primary,
            },
          }
        : undefined,
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            html: {
              scrollBehavior: 'smooth',
            },
            [`input[type='number']`]: {
              MozAppearance: 'textfield',
            },
            [`input::-webkit-inner-spin-button`]: {
              WebkitAppearance: 'none',
            },
            [`input::-webkit-outer-spin-button`]: {
              WebkitAppearance: 'none',
            },
          },
        },
      },
    });
  }, [uiSettings]);

  if (!uiSettings) {
    return null;
  }

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}

export default ThemeProvider;
