import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { rossumMessage } from '../../lib/rossumMessage';
import { secretFields, secretPlaceholder } from './constants';
import { SFTPExports } from './containers/SFTPExports';
import { SFTPSettings } from './containers/SFTPSettings';
import { TemplateVariables } from './containers/TemplateVariables';
import { SFTPExtensionSchema } from './schema';
import {
  IncomingSFTPSecrets,
  IncomingSFTPSettings,
  SFTPExtension,
} from './types';
import { getModifiedSecrets, removeExtension } from './utils';

type Props = {
  defaultValues: SFTPExtension;
};

export const Extension = ({ defaultValues }: Props) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    rossumMessage('ConfigApp.refetchOnClose', false);
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitting, isSubmitted },
  } = useForm<SFTPExtension>({
    mode: 'onChange',
    resolver: yupResolver(SFTPExtensionSchema),
    shouldFocusError: true,
    defaultValues,
  });

  const saveSettings = (savedSettings: SFTPExtension) => {
    const secrets = secretFields.reduce(getModifiedSecrets(savedSettings), {});
    const exports = savedSettings.exports.map(
      ({ filename_template, format, type }) =>
        type === 'annotation_content'
          ? {
              filename_template: `${filename_template}.${format}`,
              type,
              format,
            }
          : {
              filename_template,
              type,
            }
    );

    const { host, port, username, path } = savedSettings;
    setIsLoading(true);
    rossumMessage<IncomingSFTPSettings>('Hook.patchSettingsAndSecrets', {
      settings: {
        path,
        host,
        port,
        username,
        exports,
        template_variables: savedSettings.template_variables,
      },
      secrets,
    })
      .then(() => {
        rossumMessage('ConfigApp.finish');
      })
      .catch((e: { methodResponse: { error?: { reason: string } } }) => {
        if (e && 'error' in e.methodResponse && e.methodResponse.error) {
          return setSubmitError(e.methodResponse.error.reason);
        }
        return setSubmitError(JSON.stringify(e));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const sendingIsEnables = !isSubmitted && !isSubmitting;
    if (sendingIsEnables && isDirty) {
      rossumMessage('ConfigApp.setShouldLeaveSafely', true);
    } else {
      rossumMessage('ConfigApp.setShouldLeaveSafely', false);
    }
  }, [isDirty, isSubmitting, isSubmitted]);

  return (
    <Stack
      my={3}
      height={1}
      px={3}
      spacing={4}
      component="form"
      onSubmit={handleSubmit(saveSettings)}
    >
      <SFTPSettings control={control} />
      <SFTPExports control={control} setValue={setValue} />
      <TemplateVariables control={control} setValue={setValue} />
      {submitError && <Typography color="error">{submitError}</Typography>}
      <Button
        variant="contained"
        type="submit"
        startIcon={isLoading ? <CircularProgress size={16} /> : null}
        disabled={isLoading}
        sx={{ '&&': { ml: 'auto' } }}
      >
        Save settings
      </Button>
    </Stack>
  );
};

export const SFTPExportExtension = () => {
  const [extensionSettings, setExtensionSettings] =
    useState<SFTPExtension | null>(null);

  useEffect(() => {
    const getHook = rossumMessage<IncomingSFTPSettings>('Hook.get');
    const getSecrets = rossumMessage<IncomingSFTPSecrets>(
      'Hook.getSecretsKeys'
    );

    Promise.all([getHook, getSecrets]).then(data => {
      const [hookSettings, hookSecrets] = data;

      const secrets = Object.fromEntries(
        hookSecrets.methodResponse.value
          .filter(key => secretFields.some(secretField => secretField === key))
          .map(key => [key, secretPlaceholder])
      );

      try {
        const validatedHookSettings = SFTPExtensionSchema.omit([
          'password',
        ]).cast(hookSettings.methodResponse.value.settings, {
          stripUnknown: true,
        });

        const { path, exports, template_variables, username, port, host } =
          validatedHookSettings;

        const exportsWithoutExtensions = exports.map(value => ({
          ...value,
          filename_template: removeExtension(value.filename_template),
          format: value.type === 'document' ? '' : value.format ?? '',
        }));

        setExtensionSettings({
          host,
          port,
          path,
          username,
          password: '',
          exports: exportsWithoutExtensions,
          template_variables: template_variables ?? [],
          ...secrets,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(
          'Existing settings in JSON are in wrong format. Resetting form to defualt. Error - ',
          e
        );
        setExtensionSettings({
          host: '',
          port: '',
          username: '',
          password: '',
          path: '',
          exports: [],
          template_variables: [],
          ...secrets,
        });
      }
    });
  }, []);

  return extensionSettings ? (
    <Extension defaultValues={extensionSettings} />
  ) : null;
};
