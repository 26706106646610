import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Chip, Collapse, Stack, Typography } from '@mui/material';
import { FocusEvent, useEffect, useState } from 'react';
import { useController, useFormState, useWatch } from 'react-hook-form';

import { stringInCurlyBraces } from '../../constants';
import {
  LooseExportTypes,
  LooseFormats,
  SFTPExportFormParams,
} from '../../types';
import { TextInput } from '../../ui/TextInput';

type Props = Required<Pick<SFTPExportFormParams, 'control' | 'setValue'>> & {
  fieldId: string;
  filenameValue: string;
  name: `exports.${number}.filename_template`;
  format: LooseFormats;
  exportType: LooseExportTypes;
};

export const FilenameTemplate = ({
  control,
  setValue,
  name,
  fieldId,
  filenameValue,
  format,
  exportType,
}: Props) => {
  const {
    field: { value },
  } = useController({ control, name });
  const [filenameModifier, setFilenameModifier] = useState<{
    fieldId: string;
    showVariables: boolean;
  }>({ fieldId, showVariables: false });

  const [cursorPosition, setCursorPosition] = useState<number>(value.length);

  const templateVariables = useWatch({ control, name: 'template_variables' });

  // list of variables is needed to correctly modify filename template when the user is changing the variable names
  // it informs us about the **existing variables** before the change
  const [listOfVariables, setListOfVariables] = useState(
    templateVariables
      .map(variable => variable.name)
      .filter(variableName => variableName && !variableName.includes(' '))
  );

  const { errors } = useFormState({ control });

  // we're avoiding to have duplicates using Set
  const availableVariableNames = [
    ...new Set(
      templateVariables
        .map(variable => variable.name)
        .filter(variableName => variableName && !variableName.includes(' '))
    ),
  ];

  // this allows us to only listen to variable name changes by passing a string to the dependency array of the useEffect
  const stringifiedVariableNames = availableVariableNames.join(',');

  useEffect(() => {
    setListOfVariables(availableVariableNames);
    // we use stringified version of availableVariableNames
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedVariableNames]);

  useEffect(() => {
    const varsInFilename = [
      ...new Set(
        filenameValue
          .match(stringInCurlyBraces)
          ?.map(variableName =>
            variableName.substring(1, variableName.length - 1)
          ) ?? []
      ),
    ];

    const expiredVarNames = varsInFilename.filter(
      variableName => !availableVariableNames.includes(variableName)
    );

    const modifiedVariable = availableVariableNames.filter(
      variableName => listOfVariables.indexOf(variableName) === -1
    )[0];

    if (expiredVarNames.length) {
      const newValue = expiredVarNames.reduce(
        (acc, expiredVarName) =>
          acc.replaceAll(
            `{${expiredVarName}}`,
            modifiedVariable ? `{${modifiedVariable}}` : ''
          ),
        filenameValue
      );

      setValue(name, newValue, { shouldValidate: true, shouldDirty: true });
    }
    // we use stringified version of availableVariableNames
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedVariableNames]);

  const variableNamesExist = availableVariableNames.length > 0;

  const handleBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCursorPosition(e.target.selectionStart ?? 0);
  };

  const handleAppendVariable = ({ variableName }: { variableName: string }) => {
    const newValue = `${filenameValue.substring(
      0,
      cursorPosition
    )}{${variableName}}${filenameValue.substring(cursorPosition)}`;

    setValue(name, newValue, { shouldValidate: true, shouldDirty: true });
    setCursorPosition(newValue.length);
  };

  const handleFocus = () => {
    if (variableNamesExist)
      setFilenameModifier(prev => ({ ...prev, showVariables: true }));
  };

  const expandVariables = () => {
    setFilenameModifier(prev => ({
      ...prev,
      showVariables: !filenameModifier.showVariables,
    }));
  };

  useEffect(() => {
    if (!variableNamesExist) {
      setFilenameModifier(prev => ({ ...prev, showVariables: false }));
    }
  }, [variableNamesExist]);

  const isDocument = exportType === 'document';

  return (
    <Box flex={1}>
      <TextInput
        control={control}
        name={name}
        label="Template name"
        errors={errors}
        onFocus={handleFocus}
        onBlur={handleBlur}
        helperText={
          isDocument
            ? `Please avoid adding any format information (e.g. “.pdf”) as it will be detected during the export process.`
            : ''
        }
        endAdornment={
          isDocument ? (
            <></>
          ) : (
            <Typography
              variant="body1"
              fontSize="small"
              sx={{ color: 'text.disabled' }}
            >
              .{format}
            </Typography>
          )
        }
      />
      {variableNamesExist && (
        <>
          <Stack
            direction="row"
            spacing={1}
            onClick={expandVariables}
            sx={{
              width: 'fit-content',
              pt: 1,
              pl: 1,
              color: 'text.disabled',
              cursor: 'pointer',
              '&:hover': { color: 'text.secondary' },
            }}
          >
            <Typography color="inherit" fontSize="small">
              Available variables
            </Typography>
            {filenameModifier.showVariables ? (
              <ArrowDropUpIcon fontSize="small" />
            ) : (
              <ArrowDropDownIcon fontSize="small" />
            )}
          </Stack>
          <Collapse
            in={
              filenameModifier.showVariables &&
              filenameModifier.fieldId === fieldId
            }
          >
            <Stack spacing={1} pt={1}>
              <Stack direction="row" spacing={1}>
                {availableVariableNames.map(variableName => (
                  <Chip
                    key={variableName}
                    label={variableName}
                    onClick={() =>
                      handleAppendVariable({
                        variableName,
                      })
                    }
                  />
                ))}
              </Stack>
            </Stack>
          </Collapse>
        </>
      )}
    </Box>
  );
};
