import { Box, Stack } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';

import { muiDataGridLicenseKey } from '../config';
import { useMemoFlatSchemasWithQueues } from '../hooks/useMemoFlatSchemasWithQueues';
import { Queue } from '../types/queue';
import { Schema } from '../types/schema';
import { FieldDetail } from './field-detail/FieldDetail';
import { Overview } from './overview/Overview';
import { FieldManagerRoute } from './routes';
import { InitialLoader } from './ui/InitialLoader';

LicenseInfo.setLicenseKey(muiDataGridLicenseKey);

type Props = {
  loadingProgress: number;
  queues: Queue[];
  schemas: Schema[];
  route: FieldManagerRoute;
  setRoute: (route: FieldManagerRoute) => void;
};

export const FieldManager = ({
  loadingProgress,
  queues,
  schemas,
  route,
  setRoute,
}: Props) => {
  const flatSchemasWithQueues = useMemoFlatSchemasWithQueues(queues, schemas);

  return (
    <Box height="100%">
      {loadingProgress !== 100 ? (
        <InitialLoader loadingProgress={loadingProgress} />
      ) : (
        <Stack spacing={2} height="100%">
          {route.path === 'field-manager' ? (
            <Overview
              flatSchemasWithQueues={flatSchemasWithQueues}
              setRoute={setRoute}
              route={route}
            />
          ) : (
            <FieldDetail
              key={route.schema_id}
              setRoute={setRoute}
              route={route}
              schemas={flatSchemasWithQueues}
              schemaId={route.schema_id}
            />
          )}
        </Stack>
      )}
    </Box>
  );
};
