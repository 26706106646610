import { Box, CircularProgress, Stack, Typography } from '@mui/material';

type Props = {
  loadingProgress: number;
};

export const InitialLoader = ({ loadingProgress }: Props) => (
  <Stack alignItems="center" sx={{ pt: 10 }}>
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        size={80}
        value={loadingProgress}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          component="div"
          color="text.secondary"
        >{`${loadingProgress}%`}</Typography>
      </Box>
    </Box>
  </Stack>
);

export const getLoadingProgress = <
  PQ extends {
    data?: { pages: Array<{ pagination: { total_pages: number } }> };
  },
  MQ extends { isSuccess: boolean }
>(
  paginatedQuery: PQ,
  multipleQueries: MQ[]
) => {
  const schemasTotalCount = multipleQueries.length;
  const queuesTotalPages =
    paginatedQuery.data?.pages[0]?.pagination.total_pages;
  const schemasLoadedCount = multipleQueries.filter(
    query => query.isSuccess
  ).length;

  if (!(queuesTotalPages && schemasTotalCount)) {
    return 0;
  }

  const queuesLoadedCount = paginatedQuery.data?.pages.length ?? 0;

  const loadedCount = queuesLoadedCount + schemasLoadedCount;
  const totalCount = queuesTotalPages + schemasTotalCount;

  return Math.round((loadedCount / totalCount) * 100);
};
