import { Tooltip, Typography } from '@mui/material';

import { getFirstNonNumericKeysFromRight } from './getFirstNonNumericKeysFromRight';

type Props = {
  pathKey: string;
};

export const ErrorKey = ({ pathKey }: Props) => (
  <Tooltip title={pathKey}>
    <Typography
      component="span"
      variant="body2"
      sx={{ fontWeight: 'bold' }}
      color="error.main"
    >
      {getFirstNonNumericKeysFromRight(pathKey)}:
    </Typography>
  </Tooltip>
);
