import { isObject } from 'lodash';

import { rossumRpcMessageType, RPCError } from './rossumMessagesTypes';

export const snakeToSentenceCase = (camelCase: string) => {
  const result = camelCase.replaceAll(/_/g, ' ');
  return (result[0] ?? '').toUpperCase() + result.substring(1).toLowerCase();
};

export const isRossumErrorMessage = (error: unknown): error is RPCError =>
  !!error &&
  isObject(error) &&
  'type' in error &&
  error.type === rossumRpcMessageType &&
  'methodResponse' in error;
