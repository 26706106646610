export type FieldOptions = Array<{ value: string; label: string }>;

export const categoryOptions: FieldOptions = [
  { value: 'section', label: 'Section' },
  { value: 'multivalue', label: 'Multivalue' },
  { value: 'tuple', label: 'Tuple' },
  { value: 'datapoint', label: 'Datapoint' },
];

export const typeOptions: FieldOptions = [
  { value: 'string', label: 'String' },
  { value: 'number', label: 'Number' },
  { value: 'date', label: 'Date' },
  { value: 'enum', label: 'Enum (Dropdown options)' },
  { value: 'button', label: 'Button' },
];

export const uiConfigurationTypeOptions: FieldOptions = [
  { value: 'captured', label: 'Captured' },
  { value: 'data', label: 'Data' },
  { value: 'manual', label: 'Manual' },
];

export const uiConfigurationEditOptions: FieldOptions = [
  { value: 'enabled', label: 'Enabled' },
  { value: 'enabled_without_warning', label: 'Enabled without warning' },
  { value: 'disabled', label: 'Disabled' },
];
