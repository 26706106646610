import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { rossumMessage } from '../../../lib/rossumMessage';
import { WorkspacesResponse } from '../types/workspace';

export const useGetWorkspaces = () => {
  const workspacesPaginatedQuery = useInfiniteQuery({
    queryKey: ['workspaces-paginated'],
    queryFn: ({ pageParam = 1 }) =>
      rossumMessage<WorkspacesResponse>('ApiRequest.get', {
        endpoint: '/workspaces',
        query: {
          page_size: 100,
          page: pageParam,
          fields: ['name', 'id', 'url'],
        },
      }).then(rpcResponse => rpcResponse.methodResponse.value),
    getNextPageParam: lastPage =>
      lastPage.pagination.next
        ? Number(lastPage.pagination.next.match(/page=(\d*)/)?.[1])
        : null,
  });

  const { isFetching, hasNextPage, fetchNextPage } = workspacesPaginatedQuery;

  useEffect(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetching, hasNextPage, fetchNextPage]);

  return workspacesPaginatedQuery;
};
