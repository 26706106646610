import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { Dialog } from '../ui/Dialog';
import { GridRowModel } from './rows';

type UseRemoveFromQueues = {
  removeFromSchema: (row: GridRowModel) => void;
};

type RemoveDialogParams = {
  selectedRows: GridRowModel[];
};

export type RemoveDialogParamsSetter = (params: RemoveDialogParams) => void;

export const useRemoveFromQueues = ({
  removeFromSchema,
}: UseRemoveFromQueues) => {
  const [removeDialogParams, setRemoveDialogParamsState] =
    useState<RemoveDialogParams | null>(null);
  const open = removeDialogParams !== null;

  const setRemoveDialogParams: RemoveDialogParamsSetter = (
    p: RemoveDialogParams
  ) => {
    setRemoveDialogParamsState(p);
  };

  const removeDialog = (
    <Dialog
      onClose={() => setRemoveDialogParamsState(null)}
      open={open}
      title="Confirm delete"
      actions={
        open && (
          <Stack direction="row" spacing={1}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setRemoveDialogParamsState(null)}
              data-cy="fm-remove-dialog-cancel-button"
            >
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                removeDialogParams?.selectedRows.forEach(removeFromSchema);
                setRemoveDialogParamsState(null);
              }}
              data-cy="fm-remove-dialog-delete-button"
            >
              Delete
            </Button>
          </Stack>
        )
      }
    >
      {open && (
        <Typography variant="body2">
          Are you sure you want to delete the field from selected{' '}
          {removeDialogParams.selectedRows.length === 1 &&
          removeDialogParams.selectedRows[0]?.meta.schema_queues.length === 1
            ? 'queue'
            : 'queues'}
          ?
        </Typography>
      )}
    </Dialog>
  );

  return {
    removeDialog,
    setRemoveDialogParams,
  };
};
