import { Alert, Stack } from '@mui/material';
import { ReactNode } from 'react';

import { referrerTargetOrigin } from '../lib/rossumMessage';

type Props = {
  children: ReactNode;
};
const MissingReferrerCheck = ({ children }: Props) =>
  referrerTargetOrigin === '' ? (
    <Stack sx={{ p: 2 }}>
      <Alert severity="warning">
        Missing referrer! Accessing this app directly in browser may not work as
        expected. Open this app from Rossum app.
      </Alert>
    </Stack>
  ) : (
    <>{children}</>
  );

export default MissingReferrerCheck;
