import { Link } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { FieldManagerRoute } from '../routes';

export const FieldDetailLink =
  (setRoute: (route: FieldManagerRoute) => void) =>
  ({ value }: GridRenderCellParams) =>
    (
      <Link
        component="button"
        color="secondary"
        data-cy="fm-field-detail-link"
        onClick={() =>
          setRoute({
            path: 'field-manager/detail',
            schema_id: value,
          })
        }
      >
        {value}
      </Link>
    );
