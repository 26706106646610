import { config } from '../config';

export type SmartlookFunction = {
  (...args: unknown[]): void;
  api?: Array<unknown>;
};

/* eslint-disable func-names, no-unused-expressions, no-var, no-multi-assign, prefer-rest-params */
export const initSmartlook = () => {
  window.smartlook ||
    (function (d) {
      var o: SmartlookFunction = (window.smartlook = function () {
        o.api?.push(arguments);
      });
      const h = d.getElementsByTagName('head')[0];
      const c = d.createElement('script');

      o.api = [];
      c.async = true;
      c.type = 'text/javascript';
      c.charset = 'utf-8';
      c.src = 'https://web-sdk.smartlook.com/recorder.js';
      h?.appendChild(c);
    })(document);

  window.smartlook('init', config.smartlookProjectId, {
    region: 'eu',
  });
};
