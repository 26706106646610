import { DeleteOutline } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import {
  GridApiPro,
  gridExpandedSortedRowIdsSelector,
  GridRowId,
  gridVisibleColumnDefinitionsSelector,
} from '@mui/x-data-grid-pro';

import { GridColumns } from '../columns/types';
import { GridRowModel } from '../rows';
import { RemoveDialogParamsSetter } from '../useRemoveFromQueues';
import { EditValue } from './EditValue';

type SelectionPanelProps = {
  visible: boolean;
  editableColumns: Array<GridColumns[number] & { isBatchEditable: boolean }>;
  gridApiRef: React.MutableRefObject<GridApiPro>;
  selectedRows: GridRowModel[];
  startBatchEditMode: (params: { id: GridRowId; field: string }) => void;
  setRowSelectionModel: (selection: string[]) => void;
  openDeleteModal: RemoveDialogParamsSetter;
};

export const SelectionPanel = ({
  visible,
  editableColumns,
  gridApiRef,
  selectedRows,
  startBatchEditMode,
  setRowSelectionModel,
  openDeleteModal,
}: SelectionPanelProps) => (
  <Slide direction="right" in={visible} mountOnEnter unmountOnExit>
    <Box sx={{ position: 'relative' }}>
      <Paper
        elevation={4}
        component={Stack}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        height="100%"
        sx={{
          position: 'absolute',
          px: 2,
          mt: 2,
          minWidth: '490px',
          minHeight: theme => theme.spacing(6.75),
          backgroundColor: theme => theme.palette.background.default,
          borderRadius: 2,
          zIndex: 2,
        }}
      >
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Typography
            variant="body2"
            sx={{
              display: 'inline-block',
              minWidth: 110,
            }}
          >
            {selectedRows.length === 1
              ? `1 row`
              : `${selectedRows.length} rows`}{' '}
            selected
          </Typography>
          <EditValue
            onEditColumnValueClick={field => {
              const firstRowId = gridApiRef.current
                .getSortedRowIds()
                .find(id => gridApiRef.current.getSelectedRows().has(id));

              if (!firstRowId) {
                throw new Error('No row selected');
              }

              // handle scroll to the cell in case it is not visible
              // due to virtualization the cell does not even need to be rendered and
              // scrolling to focused input handled by browser automatically will not work
              const rowIndex = gridExpandedSortedRowIdsSelector(
                gridApiRef
              ).findIndex(id => id === firstRowId);
              const colIndex = gridVisibleColumnDefinitionsSelector(
                gridApiRef
              ).findIndex(column => column.field === field);
              gridApiRef.current.scrollToIndexes({ rowIndex, colIndex });

              startBatchEditMode({ id: firstRowId, field });
            }}
            editableColumns={editableColumns}
          />
          <Button
            color="secondary"
            onClick={() => openDeleteModal({ selectedRows })}
            startIcon={<DeleteOutline />}
            data-cy="fm-batch-remove-field"
          >
            Remove from{' '}
            {selectedRows.length === 1 &&
            selectedRows[0]?.meta.schema_queues.length === 1
              ? 'queue'
              : 'queues'}
          </Button>

          <IconButton
            onClick={() => setRowSelectionModel([])}
            data-cy="fm-batch-close-panel"
          >
            <CloseIcon
              sx={{
                opacity: 0.6,
                cursor: 'pointer',
                transition: 'opacity 0.2s',
                ':hover': { opacity: 1 },
              }}
            />
          </IconButton>
        </Stack>
      </Paper>
    </Box>
  </Slide>
);
