import {
  alpha,
  buttonClasses,
  inputBaseClasses,
  selectClasses,
  SxProps,
  Theme,
} from '@mui/material';
import {
  DEFAULT_GRID_COL_TYPE_KEY,
  getGridDefaultColumnTypes,
  gridClasses,
  GridColType,
  GridFilterOperator,
} from '@mui/x-data-grid-pro';

export const FILTER_INPUT_MIN_HEIGHT = 52;
export const FILTER_INPUT_BORDER_RADIUS = 0.5;

const filterPanelStyleOverrides: { sx: SxProps<Theme> } = {
  sx: {
    [`.${gridClasses.panelContent}`]: {
      pt: 1,
    },
    [`.${gridClasses.filterForm}`]: {
      gap: 2,
      alignItems: 'flex-start',
      [`.${gridClasses.filterFormDeleteIcon}`]: {
        alignSelf: 'center',
        order: 1,
      },
    },
    [`.${gridClasses.panelFooter}`]: {
      justifyContent: 'flex-start',
      flexDirection: 'row-reverse',
      button: {
        [`:nth-of-type(1) .${buttonClasses.startIcon}`]: {
          display: 'none',
        },
        textTransform: 'uppercase',
        color: 'secondary.main',
        '&:hover': {
          backgroundColor: t => alpha(t.palette.secondary.main, 0.08),
        },
      },
    },
  },
};

export const filterInputProps = {
  variant: 'outlined',
  color: 'secondary',
  sx: {
    [`.${inputBaseClasses.root}`]: {
      borderRadius: FILTER_INPUT_BORDER_RADIUS,
      minHeight: FILTER_INPUT_MIN_HEIGHT,
      minWidth: 80,
      [`.${selectClasses.select}, .${inputBaseClasses.input}`]: {
        fontSize: '14px',
        pr: 3,
        pl: 2,
      },
    },
  },
};

export const filterPanelProps = {
  filterFormProps: {
    columnInputProps: filterInputProps,
    operatorInputProps: filterInputProps,
    logicOperatorInputProps: filterInputProps,
    valueInputProps: filterInputProps,
  },
  ...filterPanelStyleOverrides,
};

const defaultColumnTypes = getGridDefaultColumnTypes();

export const injectStylesToOperators = <
  C extends { type?: GridColType; filterOperators?: GridFilterOperator[] }
>(
  columns: Array<C>
): Array<C> =>
  columns.map(col => {
    const filterOperators =
      col.filterOperators ??
      defaultColumnTypes[col.type ?? DEFAULT_GRID_COL_TYPE_KEY]
        ?.filterOperators;

    return {
      ...col,
      filterOperators: filterOperators?.map(operator => ({
        ...operator,
        InputComponentProps: filterInputProps,
      })),
    };
  });
