import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  formControlClasses,
  outlinedInputClasses,
  Stack,
  Typography,
} from '@mui/material';
import {
  DataGridPro,
  GridCellEditStartReasons,
  GridCellEditStopReasons,
  gridClasses,
  GridRowId,
  GridRowSelectionModel,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import update from 'immutability-helper';
import { fromPairs, get } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { rossumMessage } from '../../../../lib/rossumMessage';
import { usePatchSchemas } from '../../hooks/usePatchSchemas';
import { FlatSchemaWithQueues } from '../../types/schema';
import { aggregationRow } from '../constants';
import { FieldManagerRoute } from '../routes';
import { filterPanelProps } from '../ui/dataGridStyles';
import { Header } from '../ui/Header';
import { getAggregations } from './aggregations';
import { getColumns } from './columns/columns';
import { ExtendedCellParams } from './columns/types';
import { CustomGridCell } from './CustomGridCell';
import {
  batchEditingActive,
  cellEditabilityDisabled,
  dataGridStyles,
  rowHasError,
} from './dataGridStyles';
import { ErrorMessageWithDialog } from './errors/ErrorMessageWithDialog';
import { getProcessRowUpdate, UpdatedSchemasMap } from './getProcessRowUpdate';
import { removeItemFromSchema } from './removeItemFromSchema';
import { GridRowModel, transformSchemasToRows } from './rows';
import { SelectionPanel } from './selection/SelectionPanel';
import { useLeaveSafelyDialog } from './useLeaveSafelyDialog';
import { useRemoveFromQueues } from './useRemoveFromQueues';

type Props = {
  schemaId: string;
  schemas: Array<FlatSchemaWithQueues>;
  setRoute: (route: FieldManagerRoute) => void;
  route: FieldManagerRoute;
};

export const FieldDetail = ({ route, setRoute, schemas, schemaId }: Props) => {
  const [rows, setRows] = useState<Array<GridRowModel>>(
    transformSchemasToRows(schemaId, schemas)
  );

  const [updatedSchemas, setUpdatedSchema] = useState<UpdatedSchemasMap>({});

  const schemasToSave = Object.values(updatedSchemas);
  const schemasToSaveLength = schemasToSave.length;
  const hasNoChanges = schemasToSaveLength === 0;

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const selectionActive = rowSelectionModel.length > 0;
  const selectedRows = useMemo(
    () => rows.filter(row => rowSelectionModel.includes(row.meta.schema_id)),
    [rows, rowSelectionModel]
  );

  const [editingField, setEditingField] = useState<string | null>(null);

  const shouldWarnUser = !(editingField === null && hasNoChanges);

  const {
    leaveSafelyDialog,
    leaveSafely,
    open: alreadyOpen,
  } = useLeaveSafelyDialog(shouldWarnUser);

  useEffect(() => {
    // due to iframe limitations - do not show dialog in Elis when Field manager dialog is already open
    rossumMessage(
      'ConfigApp.setShouldLeaveSafely',
      shouldWarnUser && !alreadyOpen
    );

    return () => {
      rossumMessage('ConfigApp.setShouldLeaveSafely', false);
    };
  }, [shouldWarnUser, alreadyOpen]);

  const gridApiRef = useGridApiRef();

  const processRowUpdate = useCallback(
    (next: GridRowModel, prev: GridRowModel) => {
      const processUpdate = getProcessRowUpdate({
        setRows,
        setUpdatedSchema,
        schemas,
      });

      const shouldBatchUpdate = selectionActive && editingField !== null;

      if (shouldBatchUpdate) {
        gridApiRef.current
          .getSelectedRows()
          // @ts-expect-error GridValidRowModel returned from getSelectedRows is less strict version of GridRowModel
          .forEach(row => processUpdate(row));

        setEditingField(null);
      }

      // processRowUpdate must return updated row
      return processUpdate(next, prev);
    },
    [schemas, selectionActive, editingField, gridApiRef]
  );

  const removeFromSchema = useCallback(
    (row: GridRowModel) => {
      const { schema_id } = row.meta;

      if (schema_id === aggregationRow) {
        return;
      }

      setUpdatedSchema(prevState => {
        const alreadyInUpdated = prevState[schema_id];
        const originalSchema = schemas.find(schema => schema.id === schema_id);
        const schemaToUpdate = alreadyInUpdated ?? originalSchema;

        if (!schemaToUpdate) {
          return prevState;
        }

        const schemaWithoutRemoved = removeItemFromSchema(schemaToUpdate, row);

        return {
          ...prevState,
          [schema_id]: schemaWithoutRemoved,
        };
      });

      setRows(prevState =>
        prevState.filter(r => r.meta.schema_id !== schema_id)
      );
    },
    [schemas]
  );

  const { mutation, taskQueue } = usePatchSchemas();
  const queryClient = useQueryClient();

  const handleSubmitChanges = () => {
    mutation.mutate(schemasToSave, {
      onSuccess: ({ fulfilled }) => {
        setUpdatedSchema(prev =>
          update(prev, { $unset: fulfilled.map(({ id }) => id) })
        );

        fulfilled.forEach(schema => {
          queryClient.setQueryData(['schema', schema.url], schema);
        });
      },
    });
  };

  const { setRemoveDialogParams, removeDialog } = useRemoveFromQueues({
    removeFromSchema,
  });

  const visibleColumnsPerRows = useMemo(
    () =>
      getColumns({ openRemoveModal: setRemoveDialogParams }).filter(
        ({ editabilityCondition }) =>
          editabilityCondition === undefined ||
          rows.some(row => editabilityCondition(row.meta))
      ),
    [rows, setRemoveDialogParams]
  );

  const pinnedRows = useMemo(
    () =>
      rows.length === 0
        ? undefined
        : {
            bottom: [
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              {
                // returned value from getAggregations is of a different type than GridRowModel
                // and getting of values for Aggregations row is tailored for its needs
                ...getAggregations(visibleColumnsPerRows, rows),
                id: '0',
                meta: {
                  schema_id: aggregationRow,
                  path: [],
                },
              } as unknown as GridRowModel,
            ],
          },
    [rows, visibleColumnsPerRows]
  );

  const editableColumnsForSelectedRows = useMemo(() => {
    if (selectedRows.length === 0) {
      return [];
    }

    return visibleColumnsPerRows.flatMap(column => {
      const { editable, editabilityCondition } = column;
      return editable
        ? {
            ...column,
            isBatchEditable: editabilityCondition
              ? selectedRows.every(row => editabilityCondition(row.meta))
              : !!column.editable,
          }
        : [];
    });
  }, [selectedRows, visibleColumnsPerRows]);

  const selectedRowIds = useMemo(
    () => selectedRows.map(row => row.meta.schema_id),
    [selectedRows]
  );

  const [batchEditError, setBatchEditError] = useState<boolean>(false);
  const prevRowValues = useRef<GridValidRowModel[] | null>(null);
  const startBatchEditMode = useCallback(
    ({ id, field }: { id: GridRowId; field: string }) => {
      prevRowValues.current = Array.from(
        gridApiRef.current.getSelectedRows().values()
      );
      setEditingField(field);

      gridApiRef.current.startCellEditMode({
        id,
        field,
      });
    },
    [gridApiRef]
  );

  const revertBatchEdits = useCallback(() => {
    if (prevRowValues.current !== null) {
      gridApiRef.current.updateRows(prevRowValues.current);
    }
    setBatchEditError(false);
    setEditingField(null);
  }, [gridApiRef]);

  const mutationErrorIds = useMemo(
    () =>
      mutation.data?.rejected.map(rejected => {
        const id: number | undefined = get(rejected, 'id');
        return id;
      }),
    [mutation.data?.rejected]
  );

  // synchronize state after schemas update
  // using reset with changing key cannot be used because we want to preserve other states (such as selection, sorting etc.)
  useEffect(() => {
    if (mutationErrorIds !== undefined) {
      setRows(prevRows => {
        const newRows = transformSchemasToRows(schemaId, schemas)();
        const prevRowsMap = fromPairs(
          prevRows.map(row => [row.meta.schema_id, row])
        );

        return newRows.map(newRow => {
          if (newRow.meta.schema_id === 'aggregation-row') {
            return newRow;
          }

          return mutationErrorIds.includes(newRow.meta.schema_id)
            ? // keep previous row state in case of the error
              prevRowsMap[newRow.meta.schema_id] ?? newRow
            : newRow;
        });
      });
    }
  }, [schemas, schemaId, mutationErrorIds]);

  return (
    <Stack spacing={2} height="100%">
      {/* Box is needed to prevent screen from jumping when SelectionPanel changes visibility */}
      <Box>
        <SelectionPanel
          visible={selectionActive}
          selectedRows={selectedRows}
          setRowSelectionModel={setRowSelectionModel}
          editableColumns={editableColumnsForSelectedRows}
          startBatchEditMode={startBatchEditMode}
          openDeleteModal={setRemoveDialogParams}
          gridApiRef={gridApiRef}
        />
      </Box>

      <Header
        route={route}
        onArrowBackClick={leaveSafely(() =>
          setRoute({ path: 'field-manager' })
        )}
      >
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          minHeight={50}
        >
          {(mutation.isError || !!mutation.data?.rejected.length) && (
            <ErrorMessageWithDialog
              rejectedRequests={mutation.data?.rejected}
              schemas={schemas}
            />
          )}
          {mutation.isLoading && (
            <Typography variant="body2" color="text.secondary">
              Saving{' '}
              {Math.min(
                Math.max(schemasToSaveLength - taskQueue + 1, 1),
                schemasToSaveLength
              )}{' '}
              out of {schemasToSaveLength} schemas
            </Typography>
          )}
          <Button
            startIcon={
              mutation.isLoading && (
                <CircularProgress size={16} color="inherit" />
              )
            }
            data-cy="fm-save-changes-button"
            variant="contained"
            disabled={hasNoChanges || mutation.isLoading}
            onClick={handleSubmitChanges}
          >
            Save changes
          </Button>
        </Stack>
      </Header>

      <Box sx={dataGridStyles} height="100%">
        <DataGridPro<GridRowModel>
          disableColumnSelector
          apiRef={gridApiRef}
          columns={visibleColumnsPerRows}
          rows={rows}
          disableColumnReorder
          disableRowSelectionOnClick
          sx={{
            border: 'none',
            maxHeight: '90%', // TODO set correct height
          }}
          hideFooter
          checkboxSelection
          onRowSelectionModelChange={newRowSelectionModel => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          initialState={{
            pinnedColumns: {
              right: ['actions'],
            },
          }}
          rowSelectionModel={rowSelectionModel}
          disableColumnMenu={selectionActive}
          processRowUpdate={processRowUpdate}
          getRowId={row => row.meta.schema_id}
          pinnedRows={pinnedRows}
          slots={{
            cell: CustomGridCell,
            filterPanelDeleteIcon: Delete,
          }}
          slotProps={{
            cell: {
              selectionActive,
              setBatchEditError,
            },
            baseSelectOption: {
              // @ts-expect-error sx is not in the type definition but it works,
              sx: {
                // workaround for making first empty option having the same height as other options
                '&:after': { content: "' '" },
              },
            },
            baseFormControl: {
              sx: {
                // workaround for making singleSelect input filter full width
                [`> * .${formControlClasses.root}`]: {
                  width: '100%',
                },
              },
            },
            baseSelect: {
              native: false,
              sx: {
                [`.${gridClasses.cell} & .${outlinedInputClasses.notchedOutline}`]:
                  {
                    border: 'unset !important',
                  },
                [`& .${outlinedInputClasses.input}`]: {
                  pl: 2,
                },
              },
            },
            filterPanel: filterPanelProps,
          }}
          onCellEditStop={({ reason, id, field }, e) => {
            if (
              selectionActive &&
              reason === GridCellEditStopReasons.escapeKeyDown
            ) {
              revertBatchEdits();
              return;
            }

            // revert edits on "blur" in case there is an error
            if (
              batchEditError &&
              reason !== GridCellEditStopReasons.enterKeyDown
            ) {
              e.defaultMuiPrevented = true;
              gridApiRef.current.stopCellEditMode({
                id,
                field,
                ignoreModifications: true,
              });
              revertBatchEdits();
            }
          }}
          // onCellEditStart is not triggered when using gridApiRef.startCellEditMode
          onCellEditStart={({ reason, id, field }, e) => {
            // do not start edit on printable keydown - it breaks usage of Autocomplete because value is not an array
            if (reason === GridCellEditStartReasons.printableKeyDown) {
              e.defaultMuiPrevented = true;
              return;
            }

            if (selectionActive) {
              if (
                // do not start edit when batch editing is already active
                editingField !== null ||
                // do not start edit when cell is not editable
                !editableColumnsForSelectedRows.some(
                  c => c.field === field && c.isBatchEditable
                ) ||
                // do not start edit when cell is not in selected row
                !selectedRowIds.includes(Number(id))
              ) {
                e.defaultMuiPrevented = true;
                return;
              }

              // enter batch edit mode on focused cell
              if (editingField === null) {
                e.defaultMuiPrevented = true;

                startBatchEditMode({ id, field });
              }
            }
          }}
          isCellEditable={(params: ExtendedCellParams) =>
            params.id !== aggregationRow &&
            // control editability of cells based on the condition from column definition
            (params.colDef.editabilityCondition?.(params.row.meta) ?? true)
          }
          getRowClassName={({ id }) =>
            mutationErrorIds?.includes(Number(id)) ? rowHasError : ''
          }
          getCellClassName={(params: ExtendedCellParams) => {
            if (
              selectedRowIds.includes(Number(params.id)) &&
              params.field === editingField
            ) {
              return batchEditingActive;
            }
            // highlight originally editable cells that are not editable due to editabilityCondition
            return params.colDef.editable &&
              !params.isEditable &&
              params.id !== aggregationRow
              ? cellEditabilityDisabled
              : '';
          }}
        />
        {removeDialog}
        {leaveSafelyDialog}
      </Box>
    </Stack>
  );
};
