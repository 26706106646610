import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CardContent,
  CardHeader,
  Grow,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useFieldArray, useFormState, useWatch } from 'react-hook-form';

import { annotationFormats, documentFormats } from '../../constants';
import { SFTPExportFormParams } from '../../types';
import { StyledCard } from '../../ui/Card';
import { Dropdown } from '../../ui/Dropdown';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { SectionContainer } from '../../ui/SectionContainer';
import { TextInput } from '../../ui/TextInput';
import { FilenameTemplate } from './FilenameTemplate';

const emptyExport = {
  type: '',
  filename_template: '',
  format: '',
} as const;

export const SFTPExports = ({
  control,
  setValue,
}: Required<Pick<SFTPExportFormParams, 'control' | 'setValue'>>) => {
  const { errors } = useFormState({ control });
  const exports = useWatch({ control, name: 'exports' });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'exports',
  });

  return (
    <SectionContainer
      title="Exports"
      subheader="Add exports to configure your extension"
      onAction={() => {
        append(emptyExport, {
          focusName: `exports.${fields.length}.type`,
        });
      }}
      actionButtonText="Add new export"
    >
      {errors && <ErrorMessage errors={errors} name="exports" />}
      <StyledCard>
        <CardHeader
          subheader={
            <Stack py={1}>
              <Typography variant="subtitle1" fontSize="small">
                Export Path
              </Typography>
              <Typography sx={{ color: 'text.disabled' }} fontSize={12}>
                Please make sure if your server needs relative or absolute path:
                {` "/upload" or "upload"`}
              </Typography>
            </Stack>
          }
        />
        <CardContent>
          <TextInput
            control={control}
            name="path"
            errors={errors}
            label="Path"
          />
        </CardContent>
      </StyledCard>
      {fields.map((field, index) => {
        // When adding a new card,`fields` is updated before `exports`.
        // So in one render cycle we have field.length > exports.length which leads to `exports[index]` being undefined.
        // RHF-UFA issue #1 (if fixed, please remove it from everywhere in repo)
        const exportValue = exports[index];
        const isAnnotationContent = exportValue?.type === 'annotation_content';

        return (
          <Grow key={field.id} in>
            <StyledCard>
              <CardHeader
                subheader={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" fontSize="small">
                      Export Type
                    </Typography>
                    <IconButton color="inherit" onClick={() => remove(index)}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                }
              />
              <CardContent>
                <Stack spacing={2}>
                  <Box>
                    <Dropdown
                      control={control}
                      name={`exports.${index}.type`}
                      dropdownList={['annotation_content', 'document']}
                      label="Type"
                      labelId="export-type"
                      errors={errors}
                    />
                  </Box>
                  {exportValue ? (
                    <Stack direction="row" spacing={1}>
                      <FilenameTemplate
                        control={control}
                        name={`exports.${index}.filename_template`}
                        setValue={setValue}
                        fieldId={field.id}
                        filenameValue={exportValue.filename_template}
                        format={exportValue.format}
                        exportType={exportValue.type}
                      />
                      {isAnnotationContent && (
                        <Box flex={0.4} minWidth={85}>
                          <Dropdown
                            control={control}
                            name={`exports.${index}.format`}
                            dropdownList={
                              isAnnotationContent
                                ? annotationFormats
                                : documentFormats
                            }
                            label="Format"
                            labelId="format"
                            errors={errors}
                            defaultValue=""
                          />
                        </Box>
                      )}
                    </Stack>
                  ) : null}
                </Stack>
              </CardContent>
            </StyledCard>
          </Grow>
        );
      })}
    </SectionContainer>
  );
};
