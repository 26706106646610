import update, { CustomCommands } from 'immutability-helper';
import { get } from 'lodash';

import { pathLengthPerType } from './columns/editabilityConditions';

type UnknownObject = Record<string, unknown>;

const resolvePathAndIndex = (
  path: Array<string>,
  schema: { content: Array<UnknownObject> }
): { index: string | undefined; pathWithoutIndex: Array<string> } => {
  const index = path.slice(-1)[0];
  const pathWithoutIndex = path.slice(0, -1);

  // typically tuples and datapoints in simple multivalues
  const isChildrenOfSimpleMultivalue = index === 'children';

  // last children in tuple should lead to removal of parent multivalue datapoint
  const isLastChildrenInTuple =
    path.length === pathLengthPerType.datapointInTable &&
    get(schema.content, pathWithoutIndex).length === 1;

  return isChildrenOfSimpleMultivalue || isLastChildrenInTuple
    ? resolvePathAndIndex(pathWithoutIndex, schema)
    : { index, pathWithoutIndex };
};

export const removeItemFromSchema = <
  S extends { content: Array<UnknownObject> },
  R extends { meta: { path: Array<string> } }
>(
  schema: S,
  row: R
) => {
  const { path } = row.meta;

  const { pathWithoutIndex, index } = resolvePathAndIndex(path, schema);

  if (index === undefined) {
    throw new Error('Cannot remove item from schema without index');
  }

  const spliceOnPath = [
    'content',
    ...pathWithoutIndex,
  ].reduceRight<UnknownObject>((acc, current) => ({ [current]: acc }), {
    $splice: [[Number(index), 1]],
  });

  return update<S, CustomCommands<UnknownObject>>(schema, spliceOnPath);
};
