export const rossumRpcMessageType = 'ROSSUM:RPC';

type CommonObject = Record<string, unknown>;

export type MessageMethodCalls =
  | ['ConfigApp.setShouldLeaveSafely', boolean]
  | ['ConfigApp.refetchOnClose', boolean]
  | ['ConfigApp.finish']
  | ['ConfigApp.getUiSettings']
  | ['Hook.get']
  | ['Hook.getSecretsKeys']
  | [
      'Hook.patchSettingsAndSecrets',
      { settings?: CommonObject; secrets?: CommonObject }
    ]
  | ['ApiRequest.get', { endpoint: string; query?: CommonObject }]
  | [
      'ApiRequest.post',
      { endpoint: string; payload?: CommonObject; query?: CommonObject }
    ]
  | ['ApiRequest.patch', { endpoint: string; payload: CommonObject }]
  | ['ApiRequest.put', { endpoint: string; payload: CommonObject }]
  | ['ApiRequest.delete', { endpoint: string }];

type SuccessResponse<T> = {
  value: T;
};

type ErrorResponse = {
  error: {
    reason: string;
    meta: unknown;
  };
};

type RPCMessageShape<R> = {
  type: typeof rossumRpcMessageType;
  callId: string | number;
  methodResponse: R;
};

export type RPCResponse<T> = RPCMessageShape<SuccessResponse<T>>;

export type RPCError = RPCMessageShape<ErrorResponse>;

export type GetUiSettingsResponse = {
  locale: string;
  theme: string;
  branding?: { colors?: { primary?: string } };
};
