const Codeblock: React.FC<React.PropsWithChildren> = ({ children }) => (
  <pre
    style={{
      fontSize: '0.8em',
      maxWidth: '100%',
      overflow: 'auto',
      marginTop: 0,
      marginBottom: 0,
    }}
  >
    {children}
  </pre>
);

export default Codeblock;
