import { rossumMessage } from '../lib/rossumMessage';

type Organization = {
  id: number;
  ui_settings: Record<string, unknown>;
  is_trial: boolean;
};

export const getOrganization = () =>
  rossumMessage<{ results: Organization[] }>('ApiRequest.get', {
    endpoint: '/organizations',
  }).then(rpcResponse => rpcResponse.methodResponse.value.results[0]);
