import { GridPreProcessEditCellProps } from '@mui/x-data-grid-pro';

export const validatePositiveInteger =
  (fieldName: string) => (params: GridPreProcessEditCellProps) => {
    const hasError =
      params.props.value < 0 || !Number.isInteger(Number(params.props.value));

    return {
      ...params.props,
      error: hasError,
      // sending a special prop with error message to avoid displaying an empty tooltip on transition out
      errorMessage: `${fieldName} must be an integer greater than or equal to 0.`,
    };
  };

export const validateNonEmptyString =
  (fieldName: string) => (params: GridPreProcessEditCellProps) => {
    const hasError = params.props.value === '';

    return {
      ...params.props,
      error: hasError,
      // sending a special prop with error message to avoid displaying an empty tooltip on transition out
      errorMessage: `${fieldName} cannot be empty.`,
    };
  };
