import { Close, Warning } from '@mui/icons-material';
import {
  Box,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Grow,
  IconButton,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { FocusEvent, useMemo, useState } from 'react';
import { useFieldArray, useFormState, useWatch } from 'react-hook-form';

import { snakeToSentenceCase } from '../../../lib/helpers';
import { isKeyOf, isNotNullOrUndefined } from '../../../lib/typeguards';
import { stringInCurlyBraces } from '../constants';
import {
  Datapoint,
  Datetime,
  Sequence,
  SFTPExportFormParams,
  TemplateVariable,
  VariableCore,
  VariableTypes,
} from '../types';
import { StyledCard } from '../ui/Card';
import { Dropdown } from '../ui/Dropdown';
import { SectionContainer } from '../ui/SectionContainer';
import { TextInput } from '../ui/TextInput';

const emptyVariable: TemplateVariable = {
  name: '',
  type: '',
};

const variableTypes: VariableTypes[] = ['datetime', 'datapoint', 'sequence'];

const defaultSequenceValues: Omit<Sequence, keyof VariableCore> = {
  start: '',
  increment: '',
  padding_size: '',
  padding_character: '',
};

const defaultDateTimeValues: Omit<Datetime, keyof VariableCore> = {
  format: '',
};

const defaultDatapointValues: Omit<Datapoint, keyof VariableCore> = {
  schema_id: '',
};

const defaultFields = {
  sequence: defaultSequenceValues,
  datetime: defaultDateTimeValues,
  datapoint: defaultDatapointValues,
};

type VariableFieldParams = Required<
  Pick<SFTPExportFormParams, 'control' | 'errors'>
> & { usedVariableNames: string[]; index: number };

type FieldComponent = Omit<VariableFieldParams, 'usedVariableNames'> & {
  onFocus: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const DateFields = ({
  control,
  index,
  errors,
  onFocus,
  onBlur,
}: FieldComponent) => (
  <Stack direction="row" spacing={2}>
    <TextInput
      control={control}
      name={`template_variables.${index}.name`}
      label="Name"
      errors={errors}
      onFocus={onFocus}
      onBlur={onBlur}
    />
    <TextInput
      control={control}
      name={`template_variables.${index}.format`}
      label="Format"
      errors={errors}
    />
  </Stack>
);

const DataPointFields = ({
  control,
  index,
  errors,
  onFocus,
  onBlur,
}: FieldComponent) => (
  <Stack direction="row" spacing={2}>
    <TextInput
      control={control}
      name={`template_variables.${index}.name`}
      label="Name"
      errors={errors}
      onFocus={onFocus}
      onBlur={onBlur}
    />
    <TextInput
      control={control}
      name={`template_variables.${index}.schema_id`}
      label="Schema ID"
      errors={errors}
    />
  </Stack>
);

const SequenceFields = ({
  control,
  index,
  errors,
  onFocus,
  onBlur,
}: FieldComponent) => (
  <Stack direction="row" spacing={2} width={1} ml={0}>
    <TextInput
      control={control}
      name={`template_variables.${index}.name`}
      label="Name"
      errors={errors}
      onFocus={onFocus}
      onBlur={onBlur}
    />

    {(['start', 'increment', 'padding_size', 'padding_character'] as const).map(
      key => {
        const isNumber = ['start', 'increment', 'padding_size'].includes(key);

        return (
          <TextInput
            key={key}
            control={control}
            name={`template_variables.${index}.${key}`}
            label={snakeToSentenceCase(key)}
            type={isNumber ? 'tel' : 'text'}
            defaultValue={defaultSequenceValues[key]}
            errors={errors}
          />
        );
      }
    )}
  </Stack>
);

const fieldMap = {
  datetime: DateFields,
  datapoint: DataPointFields,
  sequence: SequenceFields,
};

type VariableComponentProps = VariableFieldParams & {
  variableType: VariableTypes;
};

const VariableComponent = ({
  variableType,
  usedVariableNames,
  control,
  index,
  errors,
}: VariableComponentProps) => {
  const [isUsed, setIsUsed] = useState(false);

  const hasError = errors.template_variables?.[index]?.name;
  const checkIfVariableIsUsed = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!hasError && usedVariableNames.includes(event.target.value))
      setIsUsed(true);
  };

  const Component = fieldMap[variableType];
  return (
    <Grow in>
      <Stack spacing={2}>
        <Component
          onFocus={checkIfVariableIsUsed}
          onBlur={() => setIsUsed(false)}
          control={control}
          index={index}
          errors={errors}
        />
        <Collapse in={!hasError && isUsed}>
          <Chip
            size="small"
            label="Changing the name of this variable will modify the template name in which it is used"
            color="warning"
            variant="outlined"
            icon={<Warning sx={{ '&&': { mx: 1 } }} />}
            sx={{ px: 1 }}
          />
        </Collapse>
      </Stack>
    </Grow>
  );
};

export const TemplateVariables = ({
  control,
  setValue,
}: Required<Pick<SFTPExportFormParams, 'control' | 'setValue'>>) => {
  const { errors } = useFormState({ control });
  const variables = useWatch({ control, name: 'template_variables' });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'template_variables',
  });

  const exports = useWatch({ control, name: 'exports' });
  const filenameTemplates = exports.map(
    ({ filename_template }) => filename_template
  );

  const usedVariableNames = useMemo(() => {
    const existingVariables = filenameTemplates
      .map(filename => filename.matchAll(stringInCurlyBraces))
      .filter(regexp => regexp)
      .map(regexp => [...regexp].map(([_, name]) => name))
      .flat()
      .filter(isNotNullOrUndefined);

    return [...new Set(existingVariables)];
  }, [filenameTemplates]);

  const handleTypeChange = (
    event: SelectChangeEvent,
    name: `template_variables.${number}`
  ) => {
    const { value } = event.target;
    const defaultValue = isKeyOf(defaultFields, value)
      ? defaultFields[value]
      : {};
    setValue(
      name,
      { ...defaultValue, type: event.target.value, name: '' },
      { shouldDirty: true }
    );
  };

  return (
    <SectionContainer
      title="Template variables"
      subheader="Add variables to construct the filename for exports"
      onAction={() =>
        append(emptyVariable, {
          focusName: `template_variables.${fields.length}.type`,
        })
      }
      actionButtonText="Add new variable"
      cardCount={variables.length}
      containsErrors={Object.keys(errors).includes('template_variables')}
      isExpandable
    >
      {fields.map((field, index) => {
        // we are not able to get the user changes from `fields` instead we need to get it from `variables`
        // search for RHF-UFA issue #1 in this repo
        const variableValue = variables[index];

        const variableType = (
          ['datapoint', 'datetime', 'sequence'] as const
        ).find(
          supportedVariableType => supportedVariableType === variableValue?.type
        );

        return (
          <Grow key={field.id} in>
            <StyledCard>
              <CardHeader
                subheader={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography fontSize="small">Variable</Typography>
                    <IconButton color="inherit" onClick={() => remove(index)}>
                      <Close fontSize="small" />
                    </IconButton>
                  </Stack>
                }
              />
              <CardContent>
                <Stack>
                  <Box mb={2}>
                    <Dropdown
                      control={control}
                      name={`template_variables.${index}.type`}
                      dropdownList={variableTypes}
                      label="Type"
                      labelId="variable-type"
                      onChange={event =>
                        handleTypeChange(event, `template_variables.${index}`)
                      }
                      errors={errors}
                    />
                  </Box>
                  {variableType ? (
                    <VariableComponent
                      control={control}
                      index={index}
                      errors={errors}
                      usedVariableNames={usedVariableNames}
                      variableType={variableType}
                    />
                  ) : (
                    <Typography
                      fontSize="small"
                      sx={{ color: 'text.disabled' }}
                    >
                      Choose variable type
                    </Typography>
                  )}
                </Stack>
              </CardContent>
            </StyledCard>
          </Grow>
        );
      })}
    </SectionContainer>
  );
};
