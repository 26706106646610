import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import { getOrganization } from '../api/organization';
import { authUserQueryKey, getAuthUser, getName } from '../api/user';
import { config } from '../config';
import { initSmartlook } from './smartlook';

export const useSmartlook = () => {
  const userQuery = useQuery({
    queryFn: getAuthUser,
    queryKey: authUserQueryKey,
  });

  const organizationQuery = useQuery({
    queryKey: ['organization'],
    queryFn: getOrganization,
  });

  const initialized = useRef(false);

  useEffect(() => {
    const user = userQuery.data;
    const organization = organizationQuery.data;

    const organizationTrackingPreference = (organization?.ui_settings ?? {})
      .tracking_enabled;

    const trackingEnabled =
      organizationTrackingPreference === undefined ||
      !!organizationTrackingPreference;

    const dataLoaded = !!(user && organization);

    if (
      dataLoaded &&
      trackingEnabled &&
      config.smartlookEnabled &&
      !initialized.current
    ) {
      initialized.current = true;
      const { id, email } = user;

      initSmartlook();

      window.smartlook?.('record', { ips: true, forms: true, numbers: true });

      window.smartlook?.('identify', id, {
        name: getName(user),
        email,
        is_trial: organization.is_trial,
        organization_id: organization.id,
      });
    }
  }, [organizationQuery.data, userQuery.data]);
};
