import * as Sentry from '@sentry/browser';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import { authUserQueryKey, getAuthUser, getName } from './api/user';

export const useSentrySetUser = () => {
  const userQuery = useQuery({
    queryFn: getAuthUser,
    queryKey: authUserQueryKey,
  });

  const user = userQuery.data;
  const userName = user ? getName(user) : '';
  const userPayload = useMemo(
    () =>
      user?.id
        ? {
            id: user.id,
            username: user?.username,
            email: user?.email,
            name: userName,
          }
        : null,
    [user?.id, user?.username, user?.email, userName]
  );

  useEffect(() => {
    if (userPayload) {
      Sentry.setUser(userPayload);
    }

    return () => {
      Sentry.setUser(null);
    };
  }, [userPayload]);
};
