import { Button, Stack } from '@mui/material';
import { useRef, useState } from 'react';

import { rossumMessage } from '../../../lib/rossumMessage';

const PatchHookPayload = ({
  setLatestMessage,
}: {
  setLatestMessage: (message: unknown) => void;
}) => {
  const [settings, setSettings] = useState<unknown>(null);
  const settingsTextAreaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <Stack spacing={1}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Button
          variant="outlined"
          size="small"
          onClick={() =>
            rossumMessage<{ settings: Record<string, unknown> }>(
              'Hook.get'
            ).then(({ methodResponse }) => {
              if ('value' in methodResponse) {
                setSettings(methodResponse.value.settings);
              }
            })
          }
        >
          Get hook settings
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            if (
              settingsTextAreaRef.current &&
              settingsTextAreaRef.current.value
            ) {
              rossumMessage('Hook.patchSettingsAndSecrets', {
                settings: JSON.parse(settingsTextAreaRef.current.value),
              })
                .then(setLatestMessage)
                .catch(setLatestMessage);
            }
          }}
        >
          Patch hook settings
        </Button>
      </Stack>
      <textarea
        defaultValue={JSON.stringify(settings ?? {}, null, 2)}
        ref={settingsTextAreaRef}
        key={JSON.stringify(settings)}
        style={{ outline: 'none', minHeight: 300, width: '100%' }}
      />
    </Stack>
  );
};

export default PatchHookPayload;
