import {
  Card,
  cardContentClasses,
  cardHeaderClasses,
  styled,
} from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => {
  const marginSpace = theme.spacing(2);

  return {
    marginTop: marginSpace,
    backgroundColor: theme.palette.background.default,
    border: '1px solid',
    borderColor: theme.palette.other.muted,
    [`& .${cardHeaderClasses.root}`]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    [`& .${cardContentClasses.root}`]: {
      paddingTop: 0,
    },
  };
});
