import { Box } from '@mui/material';
import { uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { rossumMessage } from '../../lib/rossumMessage';
import { isNotNullOrUndefined } from '../../lib/typeguards';
import { FieldManager } from './field-manager/FieldManager';
import { FieldManagerRoute } from './field-manager/routes';
import { getLoadingProgress } from './field-manager/ui/InitialLoader';
import { useGetQueues } from './hooks/useGetQueues';
import { useGetSchemas } from './hooks/useGetSchemas';
import { useTrackFieldManagerView } from './tracking';
import { Queue } from './types/queue';
import { Schema } from './types/schema';

export const FieldManagerContainer = () => {
  const [route, setRoute] = useState<FieldManagerRoute>({
    path: 'field-manager',
  });

  useEffect(() => {
    rossumMessage('ConfigApp.refetchOnClose', false);
  }, []);

  useTrackFieldManagerView(route.path);

  const queuesQuery = useGetQueues();

  const schemaUrls = useMemo(() => {
    if (!queuesQuery.isFetching && !queuesQuery.hasNextPage) {
      return uniq(
        queuesQuery.data?.pages.flatMap(page =>
          page.results.map(result => result.schema)
        )
      );
    }
    return [];
  }, [
    queuesQuery.isFetching,
    queuesQuery.hasNextPage,
    queuesQuery.data?.pages,
  ]);

  const schemaQueries = useGetSchemas(schemaUrls);

  const queues: Queue[] = useMemo(
    () => queuesQuery.data?.pages.flatMap(page => page.results) || [],
    [queuesQuery.data]
  );

  const schemaQueriesLoadedTimestamp = schemaQueries.some(
    query => query.isFetching
  )
    ? 0
    : schemaQueries.reduce((acc, { dataUpdatedAt }) => acc + dataUpdatedAt, 0);

  const schemas: Schema[] = useMemo(
    () => schemaQueries.map(({ data }) => data).filter(isNotNullOrUndefined),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schemaQueriesLoadedTimestamp]
  );

  const loadingProgress = getLoadingProgress(queuesQuery, schemaQueries);

  return (
    <Box sx={{ p: 4, pb: 6, height: '100vh' }}>
      <FieldManager
        loadingProgress={loadingProgress}
        queues={queues}
        schemas={schemas}
        setRoute={setRoute}
        route={route}
      />
    </Box>
  );
};
