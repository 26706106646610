import { useQueries } from '@tanstack/react-query';
import { useCallback } from 'react';

import { rossumMessage } from '../../../lib/rossumMessage';
import { Schema } from '../types/schema';
import { useConcurrentTaskQueue } from './useConcurrentTaskQueue';

export const useGetSchemas = (schemas: string[]) => {
  const { executeConcurrentTask } = useConcurrentTaskQueue<Schema>(25);

  const fetchSchema = useCallback(
    (schema: string) =>
      executeConcurrentTask(() =>
        rossumMessage<Schema>('ApiRequest.get', {
          endpoint: schema,
        }).then(rpcResponse => rpcResponse.methodResponse.value)
      ),
    [executeConcurrentTask]
  );

  const queries = schemas.map(schema => ({
    queryKey: ['schema', schema],
    queryFn: () => fetchSchema(schema),
  }));

  return useQueries({
    queries,
  });
};
