import {
  ErrorMessage as FormError,
  FieldValuesFromFieldErrors,
} from '@hookform/error-message';
import { Typography } from '@mui/material';
import { FieldErrors, FieldName, FieldValues } from 'react-hook-form';

import { SFTPExtension } from '../types';

type Props<TFieldErrors extends FieldValues> = {
  errors: FieldErrors<SFTPExtension>;
  name: FieldName<FieldValuesFromFieldErrors<TFieldErrors>>;
};

export const ErrorMessage = <TFieldErrors extends FieldValues>({
  errors,
  name,
}: Props<TFieldErrors>) => (
  <FormError
    errors={errors}
    name={name}
    render={error => (
      <Typography fontSize="small" color="error" pt={1} pl={1}>
        {error.message}
      </Typography>
    )}
  />
);
