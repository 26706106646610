import { rossumMessage } from '../lib/rossumMessage';

type User = {
  id: number;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
};

export const getName = ({ first_name, last_name, username, email }: User) =>
  first_name || last_name
    ? `${first_name} ${last_name}`.trim()
    : username || email;

export const authUserQueryKey = ['auth/user'] as const;

export const getAuthUser = () =>
  rossumMessage<User>('ApiRequest.get', {
    endpoint: '/auth/user',
  }).then(rpcResponse => rpcResponse.methodResponse.value);
