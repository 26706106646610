import { Box } from '@mui/material';
import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GridCellProps,
} from '@mui/x-data-grid-pro';
import { fromPairs, get } from 'lodash';

import { text, totalCountAggregationColumnField } from '../constants';
import { GridColumns } from './columns/types';
import { GridRowModel } from './rows';

type Aggregations = Record<string, Set<string>>;

export const getAggregations = (
  columns: GridColumns,
  rows: GridRowModel[]
): Aggregations =>
  rows.reduce<Aggregations>(
    (aggregatedRowFieldSets, row) =>
      columns.reduce((aggregatedColumns, column) => {
        if (column.field === totalCountAggregationColumnField) {
          return aggregatedColumns;
        }

        const flatValue =
          // @ts-expect-error colDef and column mismatch
          column.valueGetter?.({ row, field: column.field, colDef: column }) ??
          get(row, column.field) ??
          null;

        const fieldSet = aggregatedColumns[column.field];

        return fieldSet !== undefined && flatValue !== text.cellNotEditable
          ? {
              ...aggregatedColumns,
              [column.field]: fieldSet.add(String(flatValue)),
            }
          : aggregatedColumns;
      }, aggregatedRowFieldSets),
    {
      ...fromPairs(columns.map(column => [column.field, new Set<string>()])),
      [totalCountAggregationColumnField]: new Set([String(rows.length)]),
    }
  );

export const formatAggregate = (
  field: GridCellProps['fields'],
  aggregate: GridCellProps['value']
) => {
  if (field === GRID_CHECKBOX_SELECTION_FIELD || field === 'actions') {
    return null;
  }
  if (field === totalCountAggregationColumnField) {
    return <Box>{`Total: ${[...aggregate][0]}`}</Box>;
  }
  return aggregate.size > 1 ? (
    <Box color="warning.light">{`${aggregate.size} values`}</Box>
  ) : (
    <Box>Same</Box>
  );
};
