import * as yup from 'yup';

import { isNotNullOrUndefined } from '../../lib/typeguards';
import {
  annotationFormats,
  documentFormats,
  stringInCurlyBraces,
  stringWithoutWhiteSpace,
} from './constants';
import { VariableCore } from './types';

const ERROR_MESSAGE = 'This field is reqired';

const exportType = yup.object({
  type: yup.string().required(ERROR_MESSAGE),
  filename_template: yup
    .string()
    .test('isValid', 'Invalid variable name', (value, context) => {
      const templateVariables: VariableCore[] =
        context.from?.[1]?.value.template_variables;

      const availableVars = templateVariables.map(variable => variable.name);

      const variablesInFilename = [
        ...new Set(
          value
            ? [...value.matchAll(stringInCurlyBraces)]
                .map(([, name]) => name)
                .filter(isNotNullOrUndefined)
            : []
        ),
      ];

      const invalidVarsInFilename = variablesInFilename.filter(
        (variable: string) => availableVars.indexOf(variable) === -1
      );

      return invalidVarsInFilename.length > 0
        ? context.createError({
            message: `Invalid variable name: ${invalidVarsInFilename
              .map(name =>
                ['{', '}'].some(curlyBrace => name.includes(curlyBrace))
                  ? 'Nested variables are not allowed, e.g. {day{month}}'
                  : name || '{empty string}'
              )
              .join(', ')}`,
            path: context.path,
          })
        : true;
    })
    .required(ERROR_MESSAGE),
  format: yup.string().when('type', {
    is: 'annotation_content',
    then: schema =>
      schema.test('isValid', 'Format is not valid', value => {
        const allowedFormats = [...annotationFormats, ...documentFormats];

        return value ? allowedFormats.some(format => format === value) : false;
      }),
    otherwise: schema => schema.notRequired(),
  }),
});

const sequenceNumberSchema = yup
  .number()
  .typeError('Value must be a number')
  .when('type', {
    is: 'sequence',
    then: schema => schema.required(ERROR_MESSAGE),
    otherwise: schema => schema.notRequired(),
  });

const templateVariable = yup.object({
  type: yup.string().required(ERROR_MESSAGE),
  name: yup
    .string()
    .test('isUnique', 'Name must be unique', (value, context) => {
      const templateVariables: VariableCore[] =
        context.from?.[1]?.value.template_variables;

      const existingNames = templateVariables
        .map(variable => variable.name)
        .reduce<string[]>(
          (acc, name) => (name === value ? acc.concat(name) : acc),
          []
        );

      const isDuplicate = existingNames.length > 1;

      return !isDuplicate;
    })
    .matches(stringWithoutWhiteSpace, 'Name must not contain any empty spaces')
    .required(ERROR_MESSAGE),
  schema_id: yup.string().when('type', {
    is: 'datapoint',
    then: schema => schema.required(ERROR_MESSAGE),
    otherwise: schema => schema.notRequired(),
  }),
  format: yup.string().when('type', {
    is: 'datetime',
    then: schema => schema.required(ERROR_MESSAGE),
    otherwise: schema => schema.notRequired(),
  }),
  start: sequenceNumberSchema,
  increment: sequenceNumberSchema,
  padding_size: sequenceNumberSchema,
  padding_character: yup.string().when('type', {
    is: 'sequence',
    then: schema => schema.required(ERROR_MESSAGE),
    otherwise: schema => schema.notRequired(),
  }),
});

export const SFTPExtensionSchema = yup.object({
  host: yup.string().required(ERROR_MESSAGE),
  port: yup.string().required(ERROR_MESSAGE),
  username: yup.string().required(ERROR_MESSAGE),
  password: yup.string().required(ERROR_MESSAGE),
  path: yup.string().required(ERROR_MESSAGE),
  template_variables: yup.array(templateVariable),
  exports: yup
    .array(exportType)
    .test({
      message: 'Make sure to add at least 1 export',
      test: arr => arr?.length !== 0,
    })
    .required(),
});
