import { Link } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useState } from 'react';

import { referrerTargetOrigin } from '../../../../../../lib/rossumMessage';
import { Queue } from '../../../../types/queue';
import { Dialog } from '../../../ui/Dialog';
import { LinkWithNameAndId } from '../../../ui/LinkWithNameAndId';
import { GridRowModel } from '../../rows';
import { resolveQueuesDisplayedValue } from '../filterOperators';

type QueuesLinksProps = GridRenderCellParams<GridRowModel, Array<Queue>>;

const QueuesLinks = ({ value, row }: QueuesLinksProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  if (Array.isArray(value)) {
    const [firstQueue] = value;

    if (value.length === 1 && firstQueue) {
      return (
        <Link
          color="secondary"
          target="_blank"
          rel="noreferrer noopener"
          href={`${referrerTargetOrigin}/settings/queues/${firstQueue.id}/fields`}
          data-cy="fm-queue-fields-link"
        >
          {firstQueue.name}
        </Link>
      );
    }

    if (value.length > 1) {
      const label = resolveQueuesDisplayedValue(value, row.meta.schema_id);

      return (
        <>
          <Link
            component="button"
            fontSize="inherit"
            lineHeight="inherit"
            color="secondary.main"
            onClick={() => setDialogOpen(true)}
            data-cy="fm-connected-queues-link"
          >
            {label}
          </Link>
          <Dialog
            onClose={() => setDialogOpen(false)}
            open={dialogOpen}
            title={label}
          >
            {value.map(queue => (
              <LinkWithNameAndId
                link={`${referrerTargetOrigin}/settings/queues/${queue.id}/fields`}
                name={queue.name}
                id={String(queue.id)}
                key={queue.id}
              />
            ))}
          </Dialog>
        </>
      );
    }
  }

  return null;
};

export const renderQueuesLinks = (params: QueuesLinksProps) => (
  <QueuesLinks {...params} />
);
