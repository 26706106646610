import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { referrerTargetOrigin } from '../../lib/rossumMessage';
import { MessageMethodCalls } from '../../lib/rossumMessagesTypes';
import Codeblock from './components/Codeblock';
import PatchHookPayload from './components/PatchHookPayload';
import MessageCard from './MessageCard';

const messages: Array<{
  methodCall: MessageMethodCalls;
  PayloadComponent?: typeof PatchHookPayload;
  exampleParams?: Record<string, unknown>;
}> = [
  { methodCall: ['Hook.get'] },
  { methodCall: ['Hook.getSecretsKeys'] },
  {
    // @ts-expect-error - on purpose not including params here, user must use PatchHookPayload component
    methodCall: ['Hook.patchSettingsAndSecrets'],
    PayloadComponent: PatchHookPayload,
    exampleParams: {
      settings: { key0: 'value', key1: 'value1', key2: 'value2' },
      secrets: { password: '***' },
    },
  },
  { methodCall: ['ConfigApp.getUiSettings'] },
  { methodCall: ['ConfigApp.refetchOnClose', false] },
  { methodCall: ['ConfigApp.finish'] },

  {
    methodCall:
      // @ts-expect-error - this is invalid message
      ['ConfigApp.#onClose'],
  },
  {
    methodCall: [
      'ApiRequest.get',
      { endpoint: '/queues', query: { page_size: 15 } },
    ],
  },
  {
    methodCall: [
      'ApiRequest.post',
      {
        endpoint: '/annotations/usage-report',
        payload: {
          filter: {
            begin_date: '2023-06-01',
            end_data: '2023-09-30',
          },
          group_by: ['month'],
        },
      },
    ],
  },
  {
    methodCall: [
      'ApiRequest.patch',
      {
        // with hardcoded workspace ID, we will get an error message back so it's safe to use in example
        endpoint: '/workspaces/666666',
        payload: { name: 'New name from extension - PATCH' },
      },
    ],
  },
  {
    methodCall: [
      'ApiRequest.put',
      {
        // with hardcoded workspace ID, we will get an error message back so it's safe to use in example
        endpoint: '/workspaces/666666',
        payload: {
          name: 'New name from extension - PUT',
        },
      },
    ],
  },
  {
    methodCall: [
      'ApiRequest.delete',
      {
        // with hardcoded workspace ID, we will get an error message back so it's safe to use in example
        endpoint: '/workspaces/666666',
      },
    ],
  },
];

export const TestMessages = () => {
  const [enabled, setEnabled] = useState(true);
  const [latestMessage, setLatestMessage] = useState<unknown | null>(null);

  useEffect(() => {
    if (enabled) {
      const handler = ({ data, origin }: MessageEvent) => {
        if (origin !== referrerTargetOrigin) {
          return;
        }

        setLatestMessage(data);
      };
      window.addEventListener('message', handler);

      return () => {
        setLatestMessage(null);
        window.removeEventListener('message', handler);
      };
    }
  }, [enabled]);

  return (
    <Stack
      sx={{ padding: 2 }}
      spacing={2}
      direction="column"
      justifyItems="flex-start"
    >
      <Typography variant="h6">Test page for post messages</Typography>
      {messages.map(({ methodCall, PayloadComponent, exampleParams }) => (
        <MessageCard
          key={methodCall[0]}
          methodCall={methodCall}
          PayloadComponent={PayloadComponent}
          exampleParams={exampleParams}
        />
      ))}
      <Stack sx={{ padding: 2 }} spacing={1}>
        <FormControlLabel
          control={
            <Switch
              checked={enabled}
              onChange={e => setEnabled(e.target.checked)}
              size="small"
            />
          }
          label="Enable global listener"
        />
        {latestMessage !== null && (
          <Box>
            <Codeblock>{JSON.stringify(latestMessage, null, 2)}</Codeblock>
          </Box>
        )}
      </Stack>
      <Stack spacing={2} alignItems="flex-start">
        <Typography variant="h6">Error reporting test</Typography>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            throw new Error('Sentry Test Error');
          }}
        >
          👻 Throw Sentry Test Error!
        </Button>
      </Stack>
    </Stack>
  );
};
