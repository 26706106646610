import { useLayoutEffect } from 'react';

export const pushToDataLayer = <E extends { event: string }>(e: E) => {
  window.dataLayer?.push(e);
};

export const useTrackExtensionEntered = () => {
  // using useLayoutEffect to make sure that the event is pushed before other tracking events dispatched
  // from the child components
  useLayoutEffect(() => {
    pushToDataLayer({
      event: 'extension-entered',
      extension: window.location.pathname.split('/')[1],
    });
    // since each extension or app in the extension-config-ui
    // is meant to be served on a single pathname and opened once,
    // there is no necessity to track pathname changes
  }, []);
};
