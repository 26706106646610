import {
  getGridStringOperators,
  GridColDef,
  GridFilterItem,
  GridRowId,
} from '@mui/x-data-grid-pro';

import { Queue } from '../../../types/queue';
import { GridRowModel } from '../rows';

export const resolveQueuesDisplayedValue = (v: Array<Queue>, id: GridRowId) => {
  if (v.length > 1) {
    return `Connected queues (${id})`;
  }

  return v[0]?.name ?? '';
};

export const queuesOperators = getGridStringOperators()
  .filter(
    operator =>
      !['equals', 'isAnyOf', 'endsWith', 'isEmpty', 'isNotEmpty'].includes(
        operator.value
      )
  )
  .map(operator => ({
    ...operator,
    getApplyFilterFnV7: (filterItem: GridFilterItem, column: GridColDef) => {
      const filterFunction =
        operator.getApplyFilterFnV7?.(filterItem, column) ?? null;

      return filterFunction === null
        ? null
        : (
            v: Array<Queue> | Set<number> | null,
            row: GridRowModel
          ): boolean => {
            const value = Array.isArray(v)
              ? [resolveQueuesDisplayedValue(v, row.meta.schema_id)]
                  .concat(
                    // include all queue names in the filter along with Connected queues...
                    // in case of a single queue, it's already returned by resolveQueuesDisplayedValue
                    v.length > 1 ? v.map(({ name }) => name) : []
                  )
                  .join(' ')
              : null;

            // @ts-expect-error default string operators expect only first argument
            return filterFunction(value);
          };
    },
  }));
