import { DataType, GridRowModel } from '../rows';

type EditabilityConditionFn = (meta: GridRowModel['meta']) => boolean;

const dataTypeIncluded = (allowed: Array<DataType>, dataType: DataType) =>
  allowed.includes(dataType);

export const pathLengthPerType = {
  headerField: 3,
  datapointInTable: 6,
} as const;

export const isDatapointInTable: EditabilityConditionFn = meta =>
  meta.path.length === pathLengthPerType.datapointInTable;

const isHeaderField: EditabilityConditionFn = meta =>
  meta.path.length === pathLengthPerType.headerField;

export const isDatapoint: EditabilityConditionFn = meta =>
  dataTypeIncluded(['string', 'number', 'date', 'enum', 'button'], meta.type);

export const isNotButtonDatapoint: EditabilityConditionFn = meta =>
  dataTypeIncluded(['string', 'number', 'date', 'enum'], meta.type);

export const isNumberOrDate: EditabilityConditionFn = meta =>
  dataTypeIncluded(['number', 'date'], meta.type);

export const isNotButtonHeaderDatapoint: EditabilityConditionFn = meta =>
  isHeaderField(meta) && isNotButtonDatapoint(meta);

export const isNotTuple: EditabilityConditionFn = meta =>
  !dataTypeIncluded(['tuple'], meta.type);

export const isNotTupleNorSection: EditabilityConditionFn = meta =>
  !dataTypeIncluded(['tuple', 'section'], meta.type);

export const canHaveRirFieldNames: EditabilityConditionFn = meta =>
  dataTypeIncluded(
    ['string', 'date', 'number', 'enum', 'table-multivalue'],
    meta.type
  );
