import { Delete } from '@mui/icons-material';
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useState } from 'react';

import { FlatSchemaWithQueues } from '../../types/schema';
import { FieldManagerRoute } from '../routes';
import { filterPanelProps } from '../ui/dataGridStyles';
import { Header } from '../ui/Header';
import { getColumns } from './columns';
import { colorWarning, dataGridStyles } from './dataGridStyles';
import { useMemoOverviewFields } from './hooks/useMemoOverviewFields';
import { DataForQueuesDialog, QueuesDialog } from './QueuesDialog';

type OverviewProps = {
  route: FieldManagerRoute;
  setRoute: (route: FieldManagerRoute) => void;
  flatSchemasWithQueues: FlatSchemaWithQueues[];
};

export const Overview = ({
  flatSchemasWithQueues,
  route,
  setRoute,
}: OverviewProps) => {
  const [rowForQueuesDialog, setRowForQueuesDialog] =
    useState<DataForQueuesDialog | null>(null);

  const fields = useMemoOverviewFields(flatSchemasWithQueues);

  return (
    <>
      <Header route={route} />
      <Box sx={dataGridStyles} height="100%">
        <DataGridPro
          disableColumnSelector
          hideFooter
          columns={getColumns({
            setRoute,
            setRowForQueuesDialog,
          })}
          slots={{
            filterPanelDeleteIcon: Delete,
          }}
          slotProps={{
            filterPanel: filterPanelProps,
          }}
          rows={fields}
          disableColumnReorder
          disableRowSelectionOnClick
          sx={{ border: 'none', height: '90%' }}
          getCellClassName={params =>
            params.value &&
            Array.isArray(params.value) &&
            params.value.length > 1
              ? colorWarning
              : ''
          }
        />
      </Box>
      {rowForQueuesDialog !== null && (
        <QueuesDialog
          rowForQueuesDialog={rowForQueuesDialog}
          setRowForQueuesDialog={setRowForQueuesDialog}
        />
      )}
    </>
  );
};
