import { Close } from '@mui/icons-material';
import {
  alpha,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

type DialogProps = {
  onClose: () => void;
  children: ReactNode;
  open: boolean;
  title: ReactNode;
  actions?: ReactNode;
  width?: number;
};

export const Dialog = ({
  actions,
  title,
  open,
  onClose,
  children,
  width,
}: DialogProps) => (
  <MUIDialog
    onClose={onClose}
    open={open}
    PaperProps={{
      sx: {
        width: width ?? 480,
      },
      elevation: 2,
    }}
    keepMounted={false}
  >
    <DialogTitle
      sx={{
        backgroundColor: theme =>
          alpha(
            theme.palette.mode === 'dark'
              ? theme.palette.common.white
              : theme.palette.common.black,
            0.05
          ),
        p: 1,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 1 }}
      >
        <Typography
          variant="body2"
          fontWeight="bold"
          color="text.secondary"
          flexGrow={1}
        >
          {title}
        </Typography>

        <IconButton
          sx={{ color: 'text.secondary' }}
          size="small"
          onClick={onClose}
          data-cy="fm-close-dialog-button"
        >
          <Close />
        </IconButton>
      </Stack>
    </DialogTitle>
    <DialogContent>
      <Stack spacing={1} py={3} sx={{ color: 'text.primary' }}>
        {children}
      </Stack>
    </DialogContent>
    {actions && (
      <DialogActions sx={{ pb: 2, pr: 2, pt: 0 }}>{actions}</DialogActions>
    )}
  </MUIDialog>
);
