import { Queue } from '../../types/queue';
import { AnySchemaItem, FlatSchemaWithQueues } from '../../types/schema';
import { aggregationRow } from '../constants';

export type DataType =
  | 'number'
  | 'date'
  | 'enum'
  | 'button'
  | 'string'
  | 'tuple'
  | 'table-multivalue'
  | 'simple-multivalue'
  | 'section';

type OriginalField = AnySchemaItem;
export type GridRowModel = OriginalField & {
  meta: {
    schema_queues: Array<Queue>;
    schema_id: number | typeof aggregationRow;
    path: Array<string>;
    original: OriginalField;
    type: DataType;
  };
};

export const getFieldType = (schemaItem: AnySchemaItem): DataType => {
  if (schemaItem.category === 'datapoint') {
    return schemaItem.type;
  }

  if (schemaItem.category === 'section') {
    return 'section';
  }

  if (schemaItem.category === 'tuple') {
    return 'tuple';
  }

  return schemaItem.children?.category === 'tuple'
    ? 'table-multivalue'
    : 'simple-multivalue';
};

export const transformSchemasToRows =
  (schemaId: string, schemas: Array<FlatSchemaWithQueues>) =>
  (): Array<GridRowModel> => {
    const schemaOccurences = schemas.flatMap(s => {
      const field = s.flattenedContent.find(
        schemaItem => schemaItem.id === schemaId
      );
      return field ? { schema: s, field } : [];
    });

    return schemaOccurences.map(({ schema, field: { path, ...field } }) => ({
      ...field,
      meta: {
        schema_queues: schema.queues,
        path,
        type: getFieldType(field),
        schema_id: schema.id,
        original: field,
      },
    }));
  };
