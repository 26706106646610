import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { Dialog } from '../ui/Dialog';

type LeaveSafelyAction = () => void;

export const useLeaveSafelyDialog = (shouldWarn: boolean) => {
  const [leaveSafelyAction, setLeaveSafelyAction] =
    useState<LeaveSafelyAction | null>(null);
  const open = leaveSafelyAction !== null;

  const leaveSafely = (action: LeaveSafelyAction) => () => {
    if (shouldWarn) {
      // store the action to be executed when the user confirms
      // using setLeaveSafelyAction(action) immediately calls the action
      return setLeaveSafelyAction(() => action);
    }
    action();
  };

  const leaveSafelyDialog = (
    <Dialog
      open={open}
      width={400}
      title="Discard changes"
      onClose={() => setLeaveSafelyAction(null)}
      actions={
        open && (
          <Stack direction="row" spacing={1}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setLeaveSafelyAction(null)}
              data-cy="fm-unsaved-changes-continue-button"
            >
              Continue editing
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                leaveSafelyAction();
              }}
              data-cy="fm-unsaved-changes-discard-button"
            >
              Discard
            </Button>
          </Stack>
        )
      }
    >
      <Typography variant="body2">
        Do you want to discard all the changes you have made?
      </Typography>
    </Dialog>
  );

  return { leaveSafelyDialog, leaveSafely, open };
};
