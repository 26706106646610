import { GridRowsProp } from '@mui/x-data-grid-pro';
import { isEqual, uniq } from 'lodash';
import { useMemo } from 'react';

import {
  AnalyzedFields,
  FieldProperties,
  FieldPropertiesKey,
  propertiesKey,
} from '../../../types/fields';
import { Queue } from '../../../types/queue';
import {
  FlatSchemaPropertyValue,
  FlatSchemaWithQueues,
} from '../../../types/schema';

const isPropertyKey = (key: string): key is FieldPropertiesKey =>
  !!propertiesKey.find(x => x === key);

const analyzeProperty = (
  analyzedFields: AnalyzedFields,
  id: string,
  key: FieldPropertiesKey,
  value?: FlatSchemaPropertyValue | Queue[]
): FieldProperties => {
  if (value) {
    return {
      ...analyzedFields[id],
      [key]: {
        values: uniq((analyzedFields[id]?.[key]?.values || []).concat(value)),
      },
    };
  }
  return analyzedFields[id] ?? {};
};

const addSequenceIfUnique = (
  previousSequences: string[][],
  currentSequence: string[] | null
): (string[] | null)[] => {
  const newSequences: (string[] | null)[] = [...previousSequences];
  if (!previousSequences.some(values => isEqual(values, currentSequence))) {
    newSequences.push(currentSequence);
  }
  return newSequences;
};

const analyzeRirFieldNames = (
  analyzedFields: AnalyzedFields,
  id: string,
  key: FieldPropertiesKey,
  value?: FlatSchemaPropertyValue
): FieldProperties => {
  if (value !== undefined && (value === null || Array.isArray(value))) {
    const result = {
      ...analyzedFields[id],
      [key]: {
        values: addSequenceIfUnique(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          (analyzedFields[id]?.[key]?.values as string[][]) || [],
          value
        ),
      },
    };
    return result;
  }
  return analyzedFields[id] || {};
};

const getAnalyzedFields = (
  flatSchemasWithQueues: FlatSchemaWithQueues[]
): AnalyzedFields => {
  const analyzedFields: AnalyzedFields = {};

  flatSchemasWithQueues.forEach(schema => {
    schema.flattenedContent.forEach(field => {
      const { id, ...properties } = field;
      analyzedFields[id] = analyzeProperty(
        analyzedFields,
        id,
        'queues',
        schema.queues
      );
      Object.entries(properties).forEach(
        ([propertyKey, propertyValue]: [string, FlatSchemaPropertyValue]) => {
          if (propertyKey === 'rir_field_names') {
            analyzedFields[id] = analyzeRirFieldNames(
              analyzedFields,
              id,
              propertyKey,
              propertyValue
            );
          } else if (
            propertyKey === 'ui_configuration' &&
            typeof propertyValue === 'object'
          ) {
            if ('type' in propertyValue) {
              analyzedFields[id] = analyzeProperty(
                analyzedFields,
                id,
                'ui_configuration_type',
                propertyValue.type
              );
            }
            if ('edit' in propertyValue) {
              analyzedFields[id] = analyzeProperty(
                analyzedFields,
                id,
                'ui_configuration_edit',
                propertyValue.edit
              );
            }
          } else if (isPropertyKey(propertyKey)) {
            analyzedFields[id] = analyzeProperty(
              analyzedFields,
              id,
              propertyKey,
              propertyValue
            );
          }
        }
      );
    });
  });
  return analyzedFields;
};

const transformFieldsToRows = (analyzedFields: AnalyzedFields): GridRowsProp =>
  Object.entries(analyzedFields).map(([schemaId, properties], index) => ({
    id: index,
    fieldId: schemaId,
    queues: properties.queues?.values,
    label: properties.label?.values,
    category: properties.category?.values,
    dataType: properties.type?.values,
    fieldType: properties.ui_configuration_type?.values,
    editing: properties.ui_configuration_edit?.values,
    rirFieldNames: properties.rir_field_names?.values,
  }));

export const useMemoOverviewFields = (
  flatSchemasWithQueues: FlatSchemaWithQueues[]
): GridRowsProp =>
  useMemo(() => {
    if (flatSchemasWithQueues.length) {
      const analyzedFields = getAnalyzedFields(flatSchemasWithQueues);
      return transformFieldsToRows(analyzedFields);
    }
    return [];
  }, [flatSchemasWithQueues]);
