import {
  annotationFormats,
  documentFormats,
  secretFields,
  secretPlaceholder,
} from './constants';
import { SFTPExtension } from './types';

type SecretFields = (typeof secretFields)[number];

export const removeExtension = (value: string) =>
  [...documentFormats, ...annotationFormats].reduce(
    (acc, format) =>
      acc.endsWith(format) ? acc.slice(0, (format.length + 1) * -1) : acc,
    value
  );

export const getModifiedSecrets =
  (savedSettings: SFTPExtension) =>
  (acc: Record<string, string>, field: SecretFields) =>
    savedSettings[field] && savedSettings[field] !== secretPlaceholder
      ? { ...acc, [field]: savedSettings[field] }
      : acc;
