import { useMutation } from '@tanstack/react-query';

import { isRossumErrorMessage } from '../../../lib/helpers';
import { rossumMessage } from '../../../lib/rossumMessage';
import { FlatSchemaWithQueues, Schema } from '../types/schema';
import { useConcurrentTaskQueue } from './useConcurrentTaskQueue';

export type ResultsPerStatus = {
  fulfilled: Array<PromiseFulfilledResult<Schema>['value']>;
  rejected: Array<PromiseRejectedResult['reason']>;
};

export const usePatchSchemas = () => {
  const { executeConcurrentTask, taskQueue } =
    useConcurrentTaskQueue<Schema>(25);

  const mutation = useMutation({
    mutationFn: (schemas: Array<FlatSchemaWithQueues>) => {
      const schemaRequests = schemas.map(({ id, url, content }) =>
        executeConcurrentTask(() =>
          rossumMessage<Schema>('ApiRequest.patch', {
            endpoint: url,
            payload: { content },
          })
            .then(rpcResponse => rpcResponse.methodResponse.value)
            .catch(error => {
              throw isRossumErrorMessage(error)
                ? {
                    url,
                    id,
                    ...error.methodResponse,
                  }
                : error;
            })
        )
      );

      return Promise.allSettled(schemaRequests).then(results =>
        results.reduce<ResultsPerStatus>(
          (acc, result) => {
            const isError = result.status === 'rejected';

            return {
              ...acc,
              [result.status]: [
                ...acc[result.status],
                isError ? result.reason : result.value,
              ],
            };
          },
          { fulfilled: [], rejected: [] }
        )
      );
    },
  });

  return { mutation, taskQueue: taskQueue.length };
};
